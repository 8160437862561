import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ArticleTemplate from '../components/stories/ArticleTemplate';
import firebase from 'firebase/compat/app';
import { DiscussionEmbed } from 'disqus-react';
import { Paper } from '@mui/material';
import Grid from '@mui/material/Grid';
import AdBox from '../components/AdBox/AdBox';

const Article = () => {
    const location = useLocation();
    const pathname = location.pathname;
    const [article, setArticle] = useState({});
    const slug = pathname.split('stories/')[1];
    useEffect(() => {
        if (slug === '' || slug === undefined) return;
        firebase.firestore().collection('articles').where('slugName', '==', slug).get().then(
            function (snapshot) {
                const art = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data()
                }))
                setArticle(...art);
            }
        );
        //getImageFromS3(imageKey)
        //.then(imageData => {
        //    // Use the image data (e.g., display in an <img> tag)
        //    const imageUrl = URL.createObjectURL(new Blob([imageData], { type: 'image/jpeg' }));
        //    setArticle
        //})
        //.catch(error => {
        //    console.error('Error fetching image:', error);
        //});
    }, [location]);

    return (
        <Paper variant='outlined'>
            <Grid container spacing={2} sx={{ p: 0 }}>
                <Grid item xs={12} md={8} sx={{ pl: 0, pr: 0 }}>
                    <ArticleTemplate data={article} />
                    <DiscussionEmbed
                        shortname='Comments'
                        config={
                            {
                                url: `https://https-attractionsnepal-com.disqus.com/embed.js`,
                                identifier: article.id,
                                title: article.title,
                                language: 'en'
                            }
                        }
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <AdBox/>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default Article