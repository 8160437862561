import { Paper, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export default function NoPageFound() {

    return (
        <Paper>
            <Typography variant='h1' sx={{ fontSize: '36px', mb: 4, color: '#004747' }}>No page found</Typography>
            <Typography variant='body1'>
                The resource you are requesting does not exist. Go to homepage to visit the pages that are present.
            </Typography>
            <Link to="/">
                <Typography variant='h4'>
                    Homepage
                </Typography>
            </Link>
        </Paper>

    )
}
