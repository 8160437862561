export const states = [
    {
        "name": "Koshi Province",
        "description": `<p>Koshi Province, situated in the eastern part of Nepal, is a region of immense natural beauty, cultural diversity, and historical significance. With its captivating landscapes, rich heritage, and vibrant communities, Koshi Province offers a unique blend of experiences that captivate the senses and enrich the soul.</p>
<p>Spanning from the Terai plains to the towering peaks of the Himalayas, Koshi Province boasts a diverse topography that ranges from lush forests and rolling hills to majestic mountains and serene valleys.The region is graced by the majestic presence of the Koshi River, Nepal's largest river, which meanders through the landscape, enriching the land and nurturing the communities that call it home.</p>
<p>The cultural tapestry of Koshi Province is woven from a myriad of ethnic groups, languages, and traditions.Indigenous communities, including Limbus, Rais, Sherpas, and more, contribute to the province's cultural vibrancy. Festivals like Sakela and Ubhauli celebrate traditional dances, music, and rituals, allowing visitors to immerse themselves in the essence of local life.</p>
<p>At the heart of Koshi Province lies the captivating city of Biratnagar, a bustling hub of commerce, trade, and culture.As a melting pot of various communities, it exudes a dynamic energy that resonates with modernity while staying rooted in tradition.The region's urban centers offer a blend of old and new, with ancient temples and historic sites juxtaposed against modern infrastructure.</p>
<p>Nature enthusiasts will find themselves in paradise as Koshi Province is home to several protected areas and national parks.The Koshi Tappu Wildlife Reserve, a haven for birdwatchers, shelters hundreds of avian species and other wildlife in its tranquil wetlands.For trekkers and adventure seekers, the region offers the allure of destinations like the Kanchenjunga Conservation Area and the Arun Valley, where trails lead through pristine landscapes and remote communities.</p>
<p>Koshi Province's historical significance is evident in the archaeological sites and ancient monuments that dot the landscape. Sites like the Baraha Chhetra, believed to be a sacred location since ancient times, and the Dantakali Temple, dedicated to the goddess Durga, hold deep spiritual and cultural importance.</p>
<p>As one traverses Koshi Province, the interplay of nature and culture creates an unforgettable journey.The locals' warm hospitality and openness make every visitor feel like part of the community, allowing for an authentic connection with the region. Whether exploring the enchanting hills, engaging with diverse communities, or soaking in the spiritual aura of ancient sites, Koshi Province promises an immersive experience that leaves an indelible mark on the soul.</p>`
    },
    {
        "name": "Madhesh Province",
        "description": `<p>Madhesh Province, also known as Province 2, is a diverse and vibrant region located in the southeastern part of Nepal. Comprising eight districts, including Saptari, Siraha, Dhanusha, Mahottari, Sarlahi, Bara, Parsa, and Rautahat, Madhesh is characterized by its flat landscapes, fertile plains, and rich cultural heritage.</p>
<p>The province is home to a diverse population that includes people from various ethnic backgrounds, languages, and traditions. The majority of the population practices Hinduism, followed by Islam, Buddhism, and other religions. This diversity has given rise to a unique cultural fusion, where people celebrate various festivals, share cuisines, and engage in artistic expressions that reflect their heritage.</p>
<p>Agriculture is the backbone of Madhesh's economy, with the fertile plains supporting the cultivation of crops such as rice, wheat, sugarcane, and various vegetables. The region's agricultural productivity has made it a crucial contributor to the national food supply. Additionally, the province is known for its rich livestock resources, contributing to both sustenance and trade.</p>
<p>The province boasts historical significance, with several archaeological and cultural sites that provide insights into its past. Lumbini, the birthplace of Lord Buddha and a UNESCO World Heritage Site, lies in Madhesh. This draws pilgrims and tourists alike to explore the spiritual and historical significance of the region. Furthermore, Janakpur, the capital of Province 2, is revered for its religious and cultural heritage, with Janaki Temple being a prominent landmark.</p>
<p>Despite its cultural and economic significance, Madhesh also faces unique challenges. Issues such as poverty, lack of quality education and healthcare facilities, and periodic flooding due to its proximity to rivers are areas of concern that require attention and development initiatives. The region's geographical location and demographic composition necessitate efforts to address these challenges and improve the overall quality of life for its residents.</p>
<p>Efforts are underway to harness the potential of Madhesh through infrastructural development, tourism promotion, and economic diversification. The province has immense potential for trade and commerce due to its proximity to the Indo-Nepal border and connectivity with neighboring states in India.</p>
<p>In conclusion, Madhesh Province stands as a region with a rich tapestry of cultures, traditions, and economic opportunities. Its fertile plains, historical significance, and cultural diversity make it a vital part of Nepal's identity. While challenges persist, ongoing efforts to address them and leverage the province's potential will contribute to its growth and development on the national and international stage.</p>`
    },
    {
        "name": "Bagmati Province",
        "description": `<p>Bagmati Province, nestled in the heart of Nepal, is a captivating and culturally rich region known for its historical significance, natural beauty, and diverse communities. Comprising 13 districts, including Kathmandu, Lalitpur, Bhaktapur, Dhading, and Nuwakot, Bagmati Province is named after the iconic Bagmati River that flows through its lands</p>
<p>The province is home to the nation's capital, Kathmandu, which serves as the political, economic, and cultural center of Nepal. With its ancient temples, palaces, and bustling markets, Kathmandu is a melting pot of tradition and modernity. The city's Durbar Square, Swayambhunath Stupa, and Pashupatinath Temple are emblematic landmarks that draw travelers and pilgrims from around the world.</p>
<p>Bagmati Province is a treasure trove of historical sites that reflect its rich past. Bhaktapur, known as the "City of Devotees," is renowned for its well-preserved medieval architecture and UNESCO World Heritage Sites. Patan, or Lalitpur, is another ancient city famed for its Durbar Square and intricate Newari craftsmanship. These cities offer a glimpse into Nepal's history, art, and culture, showcasing a harmonious blend of Hinduism and Buddhism.</p>
<p>The region's natural beauty is equally captivating, with lush valleys, terraced fields, and towering hills that offer breathtaking panoramic views. Langtang National Park, home to diverse flora and fauna, provides opportunities for trekking and wildlife enthusiasts to explore the pristine landscapes. The Bagmati River, while facing challenges of pollution, remains an integral part of the province's ecosystem, culture, and history.</p>
<p>Bagmati Province is also a vibrant center for trade and commerce, with its strategic location connecting it to both the northern and southern borders. Its proximity to India and the Tibetan Autonomous Region of China facilitates economic activities and cross-border trade.</p>
<p>While the province thrives in many aspects, it also grapples with challenges such as urbanization, traffic congestion, pollution, and inadequate infrastructure. Environmental conservation and sustainable development remain vital concerns as the region seeks to balance its cultural heritage with modernization.</p>
<p>Efforts are being made to address these challenges and foster growth. Infrastructure development projects, environmental conservation initiatives, and cultural preservation endeavors are aimed at ensuring a harmonious and prosperous future for Bagmati Province.</p>
<p>In conclusion, Bagmati Province is a multifaceted region that holds the key to Nepal's history, culture, and progress. With its unique blend of ancient heritage and contemporary aspirations, the province continues to evolve, drawing inspiration from its rich past to shape its destiny as a vital part of Nepal's landscape.</p>`    },
    {
        "name": "Gandaki Province",
        "description": `<p>Gandaki Province, nestled in the lap of the majestic Himalayas, is a region of Nepal that captivates with its diverse landscapes, rich culture, and historical significance. Comprising 11 districts, including the famous tourist destination of Pokhara, this province is named after the iconic Gandaki River that traverses its territory.</p>
<p>The province's geographical diversity is a prominent feature, encompassing the snow-capped peaks of the Annapurna and Manaslu mountain ranges, lush valleys, serene lakes, and enchanting forests. The Annapurna Circuit trek, one of the world's most renowned trekking routes, winds through the province, offering trekkers breathtaking views of towering peaks, alpine meadows, and traditional villages.</p>
<p>Gandaki Province is home to the picturesque city of Pokhara, known as the "City of Lakes." With its tranquil Phewa Lake and the imposing Annapurna Range as a backdrop, Pokhara is a hub for adventure tourism and a gateway to the Annapurna region. The city also boasts the remarkable Davis Falls, the World Peace Pagoda, and a vibrant lakeside area lined with shops, cafes, and cultural attractions.</p>
<p>The province's cultural diversity is equally fascinating. The indigenous Gurung and Magar communities, with their unique traditions and languages, add a distinctive flavor to the region's cultural tapestry. The Manaslu Conservation Area, home to rare wildlife species like the elusive snow leopard and red panda, highlights the province's commitment to preserving its natural heritage.</p>
<p>Historically, Gandaki Province is steeped in significance. Lumbini, the birthplace of Lord Buddha and a UNESCO World Heritage Site, lies within the province's boundaries. This sacred site draws pilgrims and spiritual seekers from across the globe to explore its monasteries, gardens, and archaeological relics.</p>
<p>The province's economy is largely agrarian, with terraced fields of rice, maize, and millet covering its landscapes. Additionally, tourism contributes significantly to the local economy, as trekkers, mountaineers, and adventure enthusiasts flock to the region. Efforts to promote sustainable tourism and protect the environment are central to the province's development agenda.</p>
<p>Gandaki Province faces challenges such as infrastructure development, access to quality education, and health services. However, ongoing initiatives aim to address these issues and enhance the overall well-being of its residents. The province's commitment to sustainable practices and community engagement serves as a model for balanced growth and development.</p>
<p>In conclusion, Gandaki Province is a land of contrasts and opportunities. From its towering peaks to its serene lakes, from its cultural diversity to its historical treasures, the province captures the essence of Nepal's beauty and resilience. As it navigates the challenges of the modern world while preserving its heritage, Gandaki Province continues to beckon travelers, adventurers, and seekers of culture and nature alike.</p>`
    },
    {
        "name": "Lumbini Province",
        "description": `<p>Lumbini Province, a sacred haven nestled in the heart of Nepal, holds a special place in the hearts of millions around the world. Named after the historic birthplace of Lord Buddha, Lumbini is a region of profound spiritual significance, cultural diversity, and natural beauty. Comprising of 12 districts, this province is a testament to Nepal's rich heritage and the enduring legacy of Buddhism.</p>
<p>The focal point of Lumbini Province is undoubtedly Lumbini itself, the birthplace of Siddhartha Gautam, who would later become known as Gautam Buddha. This UNESCO World Heritage Site is a pilgrimage destination for Buddhists from across the globe, as well as a revered spot for seekers of spiritual enlightenment. The sacred garden, the Maya Devi Temple, and the Ashoka Pillar stand as silent witnesses to the birth of a philosopher whose teachings have inspired generations.</p>
<p>Lumbini's cultural mosaic is a harmonious blend of ancient traditions and modern aspirations. The province is home to diverse communities, including the Tharu, Madhesi, and indigenous groups, each contributing their unique customs, languages, and festivals. These cultural threads are interwoven, creating a vibrant tapestry that enriches the lives of residents and visitors alike.</p>
<p>The serene landscape of Lumbini Province adds to its allure. Rolling hills, fertile plains, and gentle rivers create a tranquil setting that invites reflection and introspection. The province's natural beauty complements its spiritual significance, making it a perfect place for meditation and soulful contemplation.</p>
<p>Lumbini's potential as a center for pilgrimage, education, and tourism has not gone unnoticed. The province has witnessed numerous developments to enhance its facilities and infrastructure, welcoming visitors and providing them with a comfortable experience. Monasteries, meditation centers, and educational institutions have sprung up, fostering a holistic environment for learning, meditation, and self-discovery.</p>
<p>However, Lumbini Province also faces challenges in preserving its spiritual heritage while embracing modernity. Conservation efforts are crucial to protect the sanctity of Lumbini and ensure its historical sites remain intact for future generations. Balancing economic growth with environmental sustainability is a task that requires careful planning and collaboration.</p>
<p>As Lumbini Province continues to evolve, it remains a place of profound reverence and inspiration. It serves as a bridge between ancient wisdom and contemporary aspirations, offering a space where seekers of truth, pilgrims, and curious travelers can find solace and a deeper connection to humanity. Lumbini's story is a testament to the enduring power of spirituality and the potential for harmony between diverse cultures in a world that is constantly changing.</p>`
    },
    {
        "name": "Karnali Province",
        "description": `<p>Karnali Province, nestled in the rugged landscapes of western Nepal, is a land of untamed beauty, cultural diversity, and unique experiences. This province, named after the majestic Karnali River, is characterized by its remote terrain, pristine natural wonders, and the resilience of its people.</p>
<p>Stretching across the far-western region of Nepal, Karnali Province is a realm of contrasts. From the soaring peaks of the Himalayas to the lush valleys and deep gorges, the province showcases the raw and unfiltered beauty of nature. Home to diverse flora and fauna, including rare and endangered species, Karnali Province is a haven for eco-tourism and adventure enthusiasts seeking to explore its remote trails and untouched wilderness.</p>
<p>The indigenous cultures and traditions of Karnali Province are as diverse as its landscapes. The province is inhabited by various ethnic groups, including the Thakuri, Rokaya, and Gurung, each preserving their unique heritage and languages. Traditional festivals, dances, and rituals form an integral part of the local way of life, offering visitors an opportunity to immerse themselves in the rich cultural tapestry of the region.</p>
<p>The Karnali River, one of the longest and mightiest rivers in Nepal, is the lifeblood of the province. It not only sustains the local communities but also provides opportunities for thrilling river adventures, such as white-water rafting and kayaking. The river is intertwined with the daily lives of the people, reflecting their harmonious relationship with nature.</p>
<p>Despite its natural beauty, Karnali Province faces challenges such as limited access to basic services, healthcare, and education due to its remote location. The government and various organizations are working together to bridge these gaps and improve the quality of life for its residents. The province's potential for sustainable tourism and agriculture remains untapped, and efforts are underway to harness these opportunities for economic growth and development.</p>
<p>Karnali Province holds great potential for those seeking authentic experiences and a deeper connection with nature. From trekking along rugged trails to encountering indigenous cultures and exploring hidden gems, the province offers a unique blend of adventure, cultural exchange, and natural wonder. As visitors traverse through the remote villages and breathtaking landscapes, they witness the resilience and warmth of the people who call Karnali Province their home.</p>
<p>In conclusion, Karnali Province is a land of awe-inspiring landscapes, cultural diversity, and untamed spirit. It offers a glimpse into the untamed beauty of Nepal's western frontier, where ancient traditions, remote trails, and the majestic Karnali River converge to create an unforgettable journey for those who seek adventure and a deeper connection with nature and local communities.</p>`
    },
    {
        "name": "Sudurpashchim Province",
        "description": `<p>Sudurpashchim Province, situated in the far-western corner of Nepal, is a region characterized by its enchanting landscapes, cultural diversity, and historical significance. This province, which translates to "Far Western Province," offers a unique blend of natural beauty, vibrant traditions, and a rich historical tapestry that captivates the hearts of all who visit.</p>
<p>The province is renowned for its diverse geography, ranging from the towering peaks of the Himalayas to the picturesque valleys and lush forests. The majestic Kailash Himal range stands as a sentinel, guarding the northern border, while the fertile valleys and rolling hills provide a canvas of colors that change with the seasons. Sudurpashchim Province is also blessed with numerous rivers, including the Karnali, Seti, and Mahakali, which shape the landscapes and provide sustenance to the communities.</p>
<p>Cultural diversity is one of the hallmarks of Sudurpashchim Province. The region is home to an array of ethnic groups, each with its own language, customs, and traditions. From the Tharus of Kailali to the Ranas of Bajhang, the province is a microcosm of Nepal's multicultural identity. Visitors have the opportunity to experience various festivals, rituals, and artistic expressions that reflect the richness of these cultures.</p>
<p>Historical significance permeates the province, with several sites of archaeological and cultural importance. The ancient city of Doti, once the capital of the Doti Kingdom, stands as a testament to the region's historical legacy. The ruins of temples, palaces, and fortresses provide a glimpse into the past, offering a bridge between history and the present.</p>
<p>Nature enthusiasts and adventure seekers will find solace in Sudurpashchim Province's national parks and protected areas. Khaptad National Park, with its serene landscapes and diverse wildlife, is a haven for trekkers and nature lovers. The Shuklaphanta Wildlife Reserve is home to endangered species such as the Bengal tiger and one-horned rhinoceros, providing an opportunity for wildlife enthusiasts to embark on exciting safaris.</p>
<p>Despite its natural and cultural treasures, Sudurpashchim Province also faces challenges related to development, education, and healthcare. Efforts are underway to address these issues through community-based initiatives, sustainable tourism, and local empowerment. These endeavors aim to harness the potential of the province while ensuring the preservation of its natural and cultural heritage.</p>
<p>In conclusion, Sudurpashchim Province beckons travelers to immerse themselves in its stunning landscapes, cultural diversity, and historical significance. Whether exploring the high mountains, engaging with local communities, or uncovering the secrets of ancient cities, visitors to this province will discover a world of beauty, authenticity, and heritage that leaves an indelible mark on their hearts and minds. Sudurpashchim Province is a treasure trove waiting to be explored, offering an experience that resonates with the soul of Nepal's far-western frontier.</p>`
    }]
