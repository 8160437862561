import React, { useState, useEffect } from 'react';
import './BrowseStates.css';
import { Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useArticle } from '../utils/articles';
import ArticleCard from '../components/stories/ArticleCard';
import AdBox from '../components/AdBox/AdBox';

const Stories = () => {
    //const auth = useAuth();
    //const isLoggedIn = auth.userId ? true : false;

    const articleContext = useArticle();

    const [articles, setArticles] = useState([]);

    useEffect(() => {
        if (articles.length !== 0) return;
        setArticles(articleContext.getAllArticles());
    }, [articleContext]);

    return (
        <Paper variant='outlined'>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    <Grid container>
                        <Typography variant='h1' sx={{ fontWeight: '800', fontSize: '36px', color: '#004747' }} >Recent stories</Typography>
                        <Typography variant='body1' sx={{ mb: 4 }}>Stories are the fastest way of exploring a place. Let's explore the incredible Nepal through these stories</Typography>
                        <Grid container spacing={4}>
                            {articles.map((article) => {
                                return (
                                    <Grid item>
                                        <ArticleCard
                                            data={{ ...article }}
                                        />
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <AdBox/>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default Stories