import React, { useState, useEffect } from 'react';
import { useAuth } from './../utils/auth';
import { useUser } from './../utils/users';
import { usePlaceContext } from './../context/PlaceContext';
import { useToastContext } from './../context/ToastContext';
import { getLocations } from './../api/getLocations';
import { getFeatures } from './../api/getFeatures';
import { getDeities } from './../api/getDeities';
import { placeTypes } from '../constants/placeTypes';
import { states } from '../constants/states';
import Paper from '@mui/material/Paper';
import { useParams } from 'react-router-dom';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
import { useNavigate } from 'react-router-dom';
import { validatePlaceDetails } from './../utils/placeValidation';
import ErrorSummary from '../components/ErrorSummary/ErrorSummary';

import { Typography, FormControl, Button, TextField, InputLabel, Divider, Select, MenuItem, Box } from '@mui/material';

const UpdatePlace = () => {
    const auth = useAuth();
    const userContext = useUser();
    const { id } = useParams();
    const { navigate } = useNavigate();
    const { place, updatePlace } = usePlaceContext();
    const { showToast } = useToastContext();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const [districts, setDistricts] = useState([]);
    const [localBodies, setLocalBodies] = useState([]);
    const [wardNumbers, setWardNumbers] = useState([]);
    const [moreFeatures, setMoreFeatures] = useState([]);
    const [moreDeities, setMoreDeities] = useState([]);
    const [featureList, setFeatureList] = useState([]);
    const [deityList, setDeityList] = useState([]);
    const [errors, setErrors] = useState([]);
    const [featuresPresent, setFeaturesPresent] = useState(false);
    const [deitiesPresent, setDeitiesPresent] = useState(false);
    const [showLocations, setShowLocations] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isContributor, setIsContributor] = useState(false);
    const [placeDetails, setPlaceDetails] = useState({
        placeId: '',
        placeName: '',
        placeNameNepali: '',
        placeWebsite: '',
        area: '',
        geoLocation: '',
        accessibility: '',
        wardNumber: '',
        localBody: '',
        district: '',
        state: '',
        placeType: [],
        description: '',
        placePhotos: [],
        placeVideos: [],
        phoneNumber: '',
        emailAddress: '',
        story: '',
        wardId: '',
        features: [],
        deities: []
    });

    const handleLocationSelect = (e) => {
        const { name, value } = e.target
        if (name === 'localBody') {
            setPlaceDetails({ ...placeDetails, [name]: value })
            getLocations(value, 'wards')
                .then(response => response.json())
                .then(data => setWardNumbers(data))
                .catch(e => showToast(e.message, 'error'));
        } else if (name === 'state') {
            getLocations(value, 'districts')
                .then(response => response.json())
                .then(data => setDistricts(data))
                .catch(e => showToast(e.message, 'error'));
            setPlaceDetails({ ...placeDetails, [name]: value })
        } else if (name === 'district') {
            getLocations(value, 'localbodies')
                .then(response => response.json())
                .then(data => setLocalBodies(data))
                .catch(e => showToast(e.message, 'error'));
            setPlaceDetails({ ...placeDetails, [name]: value })
        }
    };

    const handleFeatureSelect = (event) => {
        const { target: { value } } = event;
        setPlaceDetails({
            ...placeDetails,
            features:
                typeof value === 'string' ? value.split(',') : value
        });
    };

    const handleDeitySelect = (event) => {
            const { target: { value } } = event;
            setPlaceDetails({
                ...placeDetails,
                deities:
                    typeof value === 'string' ? value.split(',') : value
            });
        };

    const handlePlaceTypeSelect = (event) => {
        const { target: {value} } = event;
        setPlaceDetails({
            ...placeDetails,
            placeType: typeof value === 'string' ? value.split(',') : value
        } );
        if (event.target.value.includes('Temple')) {
            getDeities()
                .then(res => res.json())
                .then(data => setDeityList(data))
                .catch(e => showToast(e.message, 'error'))
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const validationErrors = validatePlaceDetails(placeDetails);

        if (validationErrors.length > 0) {
            setErrors(validationErrors);
        } else {
            const apiPath = 'https://places-nepal-290be81f7552.herokuapp.com/api'
            const url = apiPath + '/places/place'
            const requestOptions = {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json', key: '123456789' },
                body: JSON.stringify(placeDetails)
            };
            fetch(`${url}/${id}`, requestOptions)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    return Promise.reject(response);
                })
                .then(data => {
                    showToast(`Successfully submitted. Id=${data}`, 'success')
                    navigate(`/place/${data}`)
                })
                .catch(e => showToast(e.message, 'error'));
        }
    }

    const enableFeatures = () => {
        getFeatures()
            .then(res => res.json())
            .then(data => {
                setFeatureList(data);
                setFeaturesPresent(!featuresPresent);
                showToast('Fetched features', 'success')
            })
            .catch(e => showToast(e.message, 'error'));
    }

    const enableDeities = () => {
        getDeities()
            .then(res => res.json())
            .then(data => {
                setDeityList(data);
                setDeitiesPresent(!deitiesPresent);
                showToast('Fetched deities', 'success')
            })
            .catch(e => showToast(e.message, 'error'));
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPlaceDetails((prevValue) => ({ ...prevValue, [name]: value }));
    }

    const handleWardSelect = (e) => {
        const { value } = e.target
        const ward = wardNumbers.find(ward => ward.ward_number === value)
        setPlaceDetails({ ...placeDetails, wardId: ward.ward_id, wardNumber: ward.ward_number })
    }

    useEffect(() => {
        if (!auth.userId) return;
        if (auth.userId) {
            setIsLoggedIn(true);
        }
    }, [auth]);

    useEffect(() => {
        if (!isLoggedIn) return;
        if (isLoggedIn && isContributor) return;
        const userData = userContext.getUserData();
        setIsContributor(userData.isContributor);
    }, [userContext, isLoggedIn, isContributor]);

    useEffect(() => {
    // Initialize form values with context data
        const initialPlaceValues = {
            placeId: place.placeId,
            placeName: place.placeName,
            placeNameNepali: place.placeNameNepali,
            placeWebsite: place.placeWebsite || '',
            area: place.area || '',
            geoLocation: place.geoLocation,
            accessibility: place.accessibility,
            wardNumber: place.wardNumber,
            localBody: place.localBody,
            district: place.district,
            state: place.state,
            placeType: place.placeType,
            description: place.description,
            placePhotos: [],
            placeVideos: [],
            phoneNumber: place.phoneNumber || '',
            emailAddress: place.emailAddress || '',
            story: place.story || '',
            wardId: place.wardId,
            features: place.features || [],
            deities: place.deities || []
        };
        setPlaceDetails(initialPlaceValues);
    }, [place]);

    return (
        <Paper>
            {isContributor ?
                <>
                    <Typography variant="h2">Update place</Typography>
                    {errors.length > 0 && <ErrorSummary errors={errors} />}
                    <Divider />
                    <Box sx={{ mb: 1, p: 3 }}>
                        <Typography variant="body1">Place information</Typography>

                        <TextField
                            id="text-input-place-name"
                            type="text"
                            name="placeName"
                            onChange={handleInputChange}
                            value={placeDetails.placeName}
                            label="Place Name"
                            sx={{ m: 1, width: '25%' }}
                        />
                        <TextField
                            id="text-input-place-name-nepali"
                            type="text"
                            name="placeNameNepali"
                            onChange={handleInputChange}
                            value={placeDetails.placeNameNepali}
                            label="Place Name Nepali"
                            sx={{ m: 1, width: '25%' }}
                        />
                        <TextField
                            id="text-input-place-website"
                            type="text"
                            name="placeWebsite"
                            onChange={handleInputChange}
                            value={placeDetails.placeWebsite}
                            label="Place Website"
                            sx={{ m: 1, width: '25%' }}
                        />
                        <TextField
                            id="text-input-place-phone-number"
                            type="text"
                            name="phoneNumber"
                            onChange={handleInputChange}
                            value={placeDetails.phoneNumber}
                            label="Place phone number"
                            sx={{ m: 1, width: '25%' }}
                        />
                        <TextField
                            id="text-input-place-email-address"
                            type="email"
                            name="emailAddress"
                            onChange={handleInputChange}
                            value={placeDetails.emailAddress}
                            label="Place email address"
                            sx={{ m: 1, width: '25%' }}
                        />
                        <Divider />

                    </Box>
                    <Box sx={{ mb: 1 }}>

                        <Typography variant="body1">Type of place?</Typography>

                        <FormControl sx={{ width: '100%' }}>
                            <InputLabel id="select-input-place-type-label">Select place types</InputLabel>
                            <Select
                                name="placeType"
                                labelId="select-input-place-type-label"
                                id="select-input-place-type"
                                multiple
                                value={placeDetails.placeType}
                                label={'Select place types'}
                                onChange={handlePlaceTypeSelect}
                                input={<OutlinedInput id="select-place types" label="Place types" />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} />
                                        ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                            >
                                {placeTypes.map((place) => (
                                    <MenuItem
                                        key={place}
                                        value={place}
                                    >
                                        {place}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ mb: 1, p: 3 }}>

                        <Typography variant="body1">How to reach there?</Typography>

                        <TextField
                            id="text-input-place-place-accessibility"
                            type="text"
                            name="accessibility"
                            onChange={handleInputChange}
                            value={placeDetails.accessibility}
                            label="Place accessibility"
                            multiline
                            fullWidth
                            sx={{ m: 1 }}
                        />
                        <Divider />

                    </Box>

                    <Box sx={{ mb: 1, p: 3 }}>

                        <Typography variant="body1">Where is it?</Typography>

                        <TextField
                            id="text-input-geo-location"
                            type="text"
                            name="geoLocation"
                            onChange={handleInputChange}
                            value={placeDetails.geoLocation}
                            label="Place coordinates"
                            sx={{ m: 1, width: '25%' }}
                        />
                        <TextField
                            id="text-input-area"
                            type="text"
                            name="area"
                            onChange={handleInputChange}
                            value={placeDetails.area}
                            label="Area"
                            sx={{ m: 1, width: '25%' }}
                        />
                        <Divider />
                        <Box sx={{ p: 3 }}>
                            <Typography variant="body1"><strong>State:</strong> {placeDetails.state}</Typography>
                            <Typography variant="body1"><strong>District:</strong> {placeDetails.district}</Typography>
                            <Typography variant="body1"><strong>Localbody:</strong> {placeDetails.localBody}</Typography>
                            <Typography variant="body1"><strong>Ward number:</strong> {placeDetails.wardNumber}</Typography>
                            <Button
                                onClick={() => setShowLocations(true)}
                                variant="contained"
                                color="primary"
                                sx={{ textTransform: "none" }}
                                size="small"
                            >Change locations?</Button>
                        </Box>
                        {showLocations && (
                            <>
                                <FormControl sx={{ m: 1, minWidth: 200 }}>
                                    <InputLabel id="select-input-state-label">Select a state</InputLabel>
                                    <Select
                                        name="state"
                                        labelId="select-input-state-label"
                                        id="select-input-state"
                                        value={placeDetails.state}
                                        label="Select a state"
                                        onChange={(e) => handleLocationSelect(e)}
                                    >
                                        {states.map((state, index) => <MenuItem key={`select-state-${state}`} value={state.name}>{state.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                                {districts.length > 0 && (
                                    <FormControl sx={{ m: 1, minWidth: 200 }}>
                                        <InputLabel id="select-input-district-label">Select a district</InputLabel>
                                        <Select
                                            name="district"
                                            labelId="select-input-district-label"
                                            id="select-input-district"
                                            value={placeDetails.district}
                                            label="Select a district"
                                            onChange={(e) => handleLocationSelect(e)}
                                        >
                                            {districts.map((district, index) => <MenuItem key={`select-district-${district}`} value={district}>{district}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                )}
                                {localBodies.length > 0 && (
                                    <FormControl sx={{ m: 1, minWidth: 200 }}>
                                        <InputLabel id="select-input-localBody-label">Select a local body</InputLabel>
                                        <Select
                                            name="localBody"
                                            labelId="select-input-local-body-label"
                                            id="select-input-local-body"
                                            value={placeDetails.localBody}
                                            label="Select a local body"
                                            onChange={(e) => handleLocationSelect(e)}
                                        >
                                            {localBodies.map((localBody, index) => <MenuItem key={`select-localBody-${localBody}`} value={localBody}>{localBody}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                )}
                                {wardNumbers.length > 0 && (
                                    <FormControl sx={{ m: 1, minWidth: 200 }}>
                                        <InputLabel id="select-input-ward-label">Select a ward number</InputLabel>
                                        <Select
                                            name="wardNumber"
                                            labelId="select-input-ward-number-label"
                                            id="select-input-ward-number"
                                            value={placeDetails.wardNumber}
                                            label="Select a ward number"
                                            onChange={(e) => handleWardSelect(e)}
                                        >
                                            {wardNumbers.map((ward, index) => <MenuItem key={`select-ward-${ward.wardNumber}`} value={ward.ward_number}>{ward.ward_number}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                )}
                            </>
                        )}
                        <Divider />

                    </Box>
                    <Box sx={{ mb: 1, p: 3 }}>
                        <Typography variant="body1">What is this place about?</Typography>
                        <TextField
                            id="text-input-description"
                            type="text"
                            name="description"
                            onChange={handleInputChange}
                            value={placeDetails.description}
                            label="Place description"
                            multiline
                            fullWidth
                            sx={{ m: 1 }}
                        />
                        <Divider />
                    </Box>
                    <Box sx={{ mb: 1, p: 3 }}>
                        <Typography variant="body1">Is there any story associated with this place?</Typography>
                        <TextField
                            id="text-input-story"
                            type="text"
                            name="story"
                            onChange={handleInputChange}
                            value={placeDetails.story}
                            label="Place story"
                            multiline
                            fullWidth
                            sx={{ m: 1 }}
                        />
                        <Divider />
                    </Box>
                    <Box sx={{ mb: 1, p: 3 }}>
                        <Typography variant="body1"><strong>Selected features:</strong> {placeDetails.features.length > 0 ? placeDetails.features.join(' | ') : 'No features selected'}</Typography>

                        <Button
                            onClick={() => enableFeatures()}
                            variant="contained"
                            color="primary"
                            sx={{ textTransform: "none" }}
                            size="small"
                        >Change features?</Button>

                        {featuresPresent && (
                            <FormControl sx={{ m: 1, width: 300 }}>

                                <InputLabel id="input-select-features">Features</InputLabel>
                                <Select
                                    labelId="input-select-features-label"
                                    id="input-select-features"
                                    multiple
                                    value={placeDetails.features}
                                    onChange={handleFeatureSelect}
                                    input={<OutlinedInput id="select-features" label="Features" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value} />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {featureList.map((feature) => (
                                        <MenuItem
                                            key={feature}
                                            value={feature}
                                        >
                                            {feature}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <TextField
                                    id="text-input-add-feature"
                                    type="text"
                                    value={moreFeatures}
                                    onChange={(e) => setMoreFeatures(e.target.value)}
                                    name="add-feature"
                                    label="Add feature"
                                    sx={{ m: 1 }}
                                />
                                <Button
                                    onClick={() => setPlaceDetails({ ...placeDetails, features: placeDetails.features.concat(moreFeatures) })}
                                    variant="contained"
                                    color="info"
                                    size="small"
                                    disabled={moreFeatures === ''}
                                    sx={{ textTransform: "none" }}
                                >Add</Button>

                            </FormControl>
                        )}
                    </Box>
                    {placeDetails.placeType.includes('Temple') && (
                        <Box sx={{ mb: 1, p: 3 }}>
                            <Typography variant="body1"><strong>Selected deities:</strong> {placeDetails.deities.length > 0 ? placeDetails.deities.join(' | ') : 'No deities selected'}</Typography>

                            <Button
                                onClick={() => enableDeities()}
                                variant="contained"
                                color="primary"
                                sx={{ textTransform: "none" }}
                                size="small"
                            >Change deities?</Button>

                            {deitiesPresent && (
                                <FormControl sx={{ m: 1, width: 300 }}>

                                    <InputLabel id="input-select-deities">Deities</InputLabel>
                                    <Select
                                        labelId="input-select-deities-label"
                                        id="input-select-deities"
                                        multiple
                                        value={placeDetails.deities}
                                        onChange={handleDeitySelect}
                                        input={<OutlinedInput id="select-deities" label="Deities" />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                        {deityList.map((deity) => (
                                            <MenuItem
                                                key={deity}
                                                value={deity}
                                            >
                                                {deity}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <TextField
                                        id="text-input-add-deity"
                                        type="text"
                                        value={moreDeities}
                                        onChange={(e) => setMoreDeities.target.value}
                                        name="add-deities"
                                        label="Add deity"
                                        sx={{ m: 1 }}
                                    />
                                    <Button
                                        onClick={() => setPlaceDetails({ ...placeDetails, deities: placeDetails.deities.concat(moreDeities) })}
                                        variant="contained"
                                        color="info"
                                        size="small"
                                        disabled={moreDeities === ''}
                                        sx={{ textTransform: "none" }}
                                    >Add</Button>

                                </FormControl>
                            )}
                        </Box>
                    )}

                    <Button onClick={handleSubmit} variant="contained" color="warning" size="large" sx={{ textTransform: "none" }}>Submit</Button>
                </>
                :
                <p>Not logged in!</p>}
        </Paper>
    )
}

export default UpdatePlace