import React, { useState, useEffect } from "react";

import { useAuth } from '../../utils/auth';
import { useUser } from '../../utils/users';
import { useArticle } from '../../utils/articles';

import EditArticle from "../../components/stories/EditArticle";

import format from 'dateformat';
import { Button, Tab, Table, TableCell, TableRow, TableHead, TableBody, TableContainer, Paper } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import parse from 'html-react-parser';


function MyArticles() {

 
     const auth = useAuth();
    const userId = auth.userId;
      const userContext = useUser();

    const articleContext = useArticle();

    const [renderArticle, setRenderArticle] = useState(false);
    const [selectedArticle, setSelectedArticle] = useState({});
    const [isLoggedIn, setIsLoggedIn] = useState(false);
      const [isContributor, setIsContributor] = useState(false);


    const [articles, setArticles] = useState([]);

        useEffect(() => {
        if (!auth.userId) return;
        if (auth.userId) {
            setIsLoggedIn(true);
        }
        }, [auth]);
        
        useEffect(() => {
        if (!isLoggedIn) return;
        if (isLoggedIn && isContributor) return;
        const userData = userContext.getUserData();
        setIsContributor(userData.isContributor);
        }, [userContext]);
    
    useEffect(() => {
        if (articles.length !== 0) return;
        setArticles(articleContext.getMyArticles());
    }, [articleContext]);

    const renderEditArticle = (props) => {
        setRenderArticle(true);
        setSelectedArticle(props);
    }

    const deleteArticle = (props) => {
        articleContext.deleteArticle(props);
    }

    function parseHTML(props) {
        return parse(props);
    }
    const [value, setValue] = React.useState('1');
    const handleChange = (event, newValue) => { setValue(newValue) };
    return (
        <Paper>
            {renderArticle && (
                <EditArticle article={{...selectedArticle}} />
            )}
            {!renderArticle && (
                <div>
                    <div>
                            <h1>My Articles</h1>
                            <p>Select any of your articles, complete them and publish them</p>
                    </div>
                    <TabContext value={value}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Draft" value="1" />
                            <Tab label="Published" value="2" />
                        </TabList>
                        <TabPanel value="1">
                            <TableContainer>
                                <Table striped={'true'} bordered={'true'} hover={'true'}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Title</TableCell>
                                            <TableCell>Article</TableCell>
                                            <TableCell>Created On:</TableCell>
                                            <TableCell>Last Edited:</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {articles.length > 0 && articles.filter(article => article.published === false).map((article) => {
                                            return (
                                                <TableRow key={article.id}>
                                                    <TableCell>{article.title}</TableCell>
                                                    <TableCell>{parseHTML(article.body.substring(0, 50))}</TableCell>
                                                    <TableCell>{format(article.dateCreated, 'ddd mmm dd yyyy HH:MM:ss')}</TableCell>
                                                    <TableCell>{format(article.dateUpdated, 'ddd mmm dd yyyy HH:MM:ss')}</TableCell>
                                                    <TableCell>
                                                        <Button variant="contained" color="success" onClick={() => renderEditArticle(article)}>Edit</Button>
                                                        <Button variant="contained" color="warning" onClick={() => deleteArticle(article.id)}>Delete</Button>
                                                    </TableCell>

                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            
                        </TabPanel>
                        <TabPanel value="2">
                            <TableContainer>
                                <Table striped={'true'} bordered={'true'} hover={'true'}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Title</TableCell>
                                            <TableCell>Article</TableCell>
                                            <TableCell>Created On:</TableCell>
                                            <TableCell>Last Edited:</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {articles.length > 0 && articles.filter(article => article.published === true).map((article) => {
                                            return (
                                                <TableRow key={article.id}>
                                                    <TableCell>{article.title}</TableCell>
                                                    <TableCell>{parse(article.body.substring(0, 50))}</TableCell>
                                                    <TableCell>{format(article.dateCreated, 'ddd mmm dd yyyy HH:MM:ss')}</TableCell>
                                                    <TableCell>{format(article.dateUpdated, 'ddd mmm dd yyyy HH:MM:ss')}</TableCell>
                                                    <TableCell>
                                                        <Button variant="contained" color="success" onClick={() => renderEditArticle(article)}>Edit</Button>
                                                        <Button variant="contained" color="warning" block onClick={() => deleteArticle(article.id)}>Delete</Button>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer> 
                            
                        </TabPanel>
                    </TabContext>
                </div>
            )}

        </Paper>
    );
}

export default MyArticles;