const compulsoryFields = [
    'placeName',
    'placeNameNepali',
    'geoLocation',
    'accessibility',
    'placeType',
    'description',
    'wardId'
];

export const validatePlaceDetails = (placeDetails) => {
    const errors = [];

    compulsoryFields.forEach(field => {
    if (Array.isArray(placeDetails[field])) {
        if (placeDetails[field].length === 0) {
        errors.push(field);
        }
    } else {
        if (!placeDetails[field]) {
        errors.push(field);
        }
    }
    });

    return errors;
};