import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import GoogleAdCard from '../Cards/GoogleAdCard';
import GygAdCard from '../Cards/GygAdCard';

export default function AdBox() {
    return (
        <Box sx={{ border: '1px solid #d5c28f', backgroundColor: '#eee'}}>
            <Typography variant='body1' sx={{ fontWeight: '200', fontSize: '0.8rem' }}>Advertisements:</Typography>
{/*             <GoogleAdCard/> */}
            <GygAdCard/>
        </Box>
    );
}
