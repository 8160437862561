import React from 'react';
import { Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import AdBox from '../../components/AdBox/AdBox';
import MapView from '../../components/Maps/MapView';

const PlacesMap = () => {
    return (
        <Paper variant='outlined'>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    <Container fixed>
                        <Typography variant='h1' sx={{ fontWeight: '800', fontSize: '30px', mb: 2, color: '#004747' }} >Browse places by map</Typography>
                        <Typography variant='body1' sx={{ mb: 4 }} >Use our interactive map to search places in any area. Use zoom in and zoom out buttons to zoom in and zoom out of any area</Typography>
                        <Grid container spacing={4} sx={{ pl: 3 }}>
                            <MapView />
                        </Grid>
                    </Container>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <AdBox/>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default PlacesMap