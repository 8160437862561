export const placeTypes = [
    "Trek point",
    "Waterfall",
    "Park",
    "Amusement park",
    "Water park",
    "Temple",
    "Monastery",
    "Stupa",
    "Religious place",
    "National landmark",
    "Spiritual place",
    "Retreat",
    "View point",
    "Lake",
    "River beach",
    "Adventure",
    "Library",
    "Museum",
    "Animal sanctuary",
    "Cultural",
    "Architectural",
    "Historical",
    "Cave",
    "Village",
    "Hilltop",
    "Bazaar",
    "Natural landscape",
    "Estate",
    "View tower",
    "Bridge",
    "Hot water spring"
]
