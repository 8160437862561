import React, { useState, useEffect } from 'react';
import { useAuth } from './../utils/auth';
import { useUser } from './../utils/users';
import { usePlaceContext } from './../context/PlaceContext';
import { useToastContext } from './../context/ToastContext';
import { getLocations } from './../api/getLocations';
import { getDeities } from './../api/getDeities';
import { getFeatures } from './../api/getFeatures';
import { placeTypes } from '../constants/placeTypes';
import { states } from '../constants/states';
import Paper from '@mui/material/Paper';
import { Typography, FormControl, Button, TextField, InputLabel, Divider, Select, MenuItem, Box } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import { validatePlaceDetails } from './../utils/placeValidation';
import ErrorSummary from '../components/ErrorSummary/ErrorSummary';

const SavePlace = () => {

    const auth = useAuth();
    const userContext = useUser();
    const { place, updatePlace } = usePlaceContext();
    const { showToast } = useToastContext();

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const [moreDieties, setMoreDieties] = useState([]);
    const [moreFeatures, setMoreFeatures] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [localBodies, setLocalBodies] = useState([]);
    const [wardNumbers, setWardNumbers] = useState([]);
    const [deityList, setDeityList] = useState([]);
    const [featureList, setFeatureList] = useState([]);
    const [featuresPresent, setFeaturesPresent] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isContributor, setIsContributor] = useState(false);
    const [errors, setErrors] = useState([]);

    const handleLocationSelect = (e) => {
        const { name, value } = e.target
        if (name === 'localBody') {
            updatePlace({ ...place, [name]: value })
            getLocations(value, 'wards')
                .then(response => response.json())
                .then(data => { setWardNumbers(data) })
                .catch(e => showToast(e.message, 'error'));
        } else if (name === 'state') {
            getLocations(value, 'districts')
                .then(response => response.json())
                .then(data => setDistricts(data))
                .catch(e => showToast(e.message, 'error'));
            updatePlace({ ...place, [name]: value })
        } else if (name === 'district') {
            getLocations(value, 'localbodies')
                .then(response => response.json())
                .then(data => setLocalBodies(data))
                .catch(e => showToast(e.message, 'error'));
            updatePlace({ ...place, [name]: value })
        }
    }

    const handleWardSelect = (e) => {
        const { value } = e.target
        const ward = wardNumbers.find(ward => ward.ward_number === value)
        updatePlace({ ...place, wardId: ward.ward_id, wardNumber: ward.ward_number })
    }

    const mapPlace = (place) => {
        return {
            placeName: place.placeName,
            placeNameNepali: place.placeNameNepali,
            placeWebsite: place.placeWebsite ? place.placeWebsite : null,
            area: place.area,
            geoLocation: place.geoLocation,
            accessibility: place.accessibility,
            placeType: place.placeType.length > 0 ? place.placeType : [],
            description: place.description,
            placePhotos: place.placePhotos.length > 0 ? place.placePhotos : [],
            placeVideos: place.placeVideos.length > 0 ? place.placeVideos : [],
            phoneNumber: place.phoneNumber ? place.phoneNumber : null,
            emailAddress: place.emailAddress ? place.emailAddress : null,
            story: place.story ? place.story : null,
            wardId: place.wardId,
            features: place.features.length > 0 ? place.features : [],
            deities: place.deities.length > 0 ? place.deities : []
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const placeRequestBody = mapPlace(place);
        const validationErrors = validatePlaceDetails(placeRequestBody);

       if (validationErrors.length > 0) {
          setErrors(validationErrors);
       } else {
          const apiPath = 'https://places-nepal-290be81f7552.herokuapp.com/api'
          const url = apiPath + '/places/place'
          const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json', key: '123456789' },
              body: JSON.stringify(place)
          };
          fetch(url, requestOptions)
              .then(response => response.json())
              .then(data => {
                  showToast(`Successfully saved: ${data.id}`, 'success');
                  window.location.reload();
              })
       }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        console.log('Name and value', name, value);
        updatePlace({[name]: value});
    }

    const handleDeitySelect = (event) => {
        const { target: { value }} = event;
        updatePlace({
            ...place,
            deities:
                typeof value === 'string' ? value.split(',') : value
        });
    };

    const handleFeatureSelect = (event) => {
        const { target: { value } } = event;
        updatePlace({
            ...place,
            features:
                typeof value === 'string' ? value.split(',') : value
        });
    };

    const handlePlaceTypeSelect = (event) => {
        const { target: {value} } = event;
        updatePlace({
            ...place,
            placeType: typeof value === 'string' ? value.split(',') : value
        } );
        if (event.target.value.includes('Temple')) {
            getDeities()
                .then(res => res.json())
                .then(data => setDeityList(data))
                .catch(e => showToast(e.message, 'error'))
        }
    }

    const enableFeatures = () => {
        getFeatures()
            .then(res => res.json())
            .then(data => {
                setFeatureList(data);
                setFeaturesPresent(true);
            })
            .catch(e => {
                showToast(e.message, 'error');
                setFeaturesPresent(false);
            });
    }

    useEffect(() => {
        if (!auth.userId) return;
        if (auth.userId) {
            setIsLoggedIn(true);
        }
    }, [auth]);

    useEffect(() => {
        if (!isLoggedIn) return;
        if (isLoggedIn && isContributor) return;
        const userData = userContext.getUserData();
        setIsContributor(userData.isContributor);
    }, [userContext, isLoggedIn, isContributor]);

    return (
        <Paper>
            {isContributor ?
                <>
                    <Typography variant="h2">Add a place</Typography>
                    {errors.length > 0 && <ErrorSummary errors={errors} />}
                    <Divider />
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Box sx={{ mb: 1, mt: 2 }}>
                                <Typography variant="body1">Place information</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="text-input-place-name"
                                            type="text"
                                            name="placeName"
                                            onChange={handleInputChange}
                                            value={place.placeName}
                                            label="Place Name"
                                            required
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="text-input-place-name-nepali"
                                            type="text"
                                            name="placeNameNepali"
                                            onChange={handleInputChange}
                                            value={place.placeNameNepali}
                                            label="Place Name Nepali"
                                            required
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <TextField
                                            id="text-input-place-website"
                                            type="text"
                                            name="placeWebsite"
                                            onChange={handleInputChange}
                                            value={place.placeWebsite}
                                            label="Place Website"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            id="text-input-place-phone-number"
                                            type="text"
                                            name="phoneNumber"
                                            onChange={handleInputChange}
                                            value={place.phoneNumber}
                                            label="Place phone number"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <TextField
                                            id="text-input-place-email-address"
                                            type="email"
                                            name="emailAddress"
                                            onChange={handleInputChange}
                                            value={place.emailAddress}
                                            label="Place email address"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ mb: 1 }}>

                                <Typography variant="body1">Type of place?</Typography>

                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id="select-input-place-type-label">Select place types</InputLabel>
                                    <Select
                                        name="placeType"
                                        labelId="select-input-place-type-label"
                                        id="select-input-place-type"
                                        multiple
                                        value={place.placeType}
                                        label={'Select place types'}
                                        onChange={handlePlaceTypeSelect}
                                        input={<OutlinedInput id="select-place types" label="Place types" />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                        {placeTypes.map((place) => (
                                            <MenuItem
                                                key={place}
                                                value={place}
                                            >
                                                {place}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ mb: 1 }}>

                                <Typography variant="body1">How to reach there?</Typography>

                                <TextField
                                    id="text-input-place-place-accessibility"
                                    type="text"
                                    name="accessibility"
                                    onChange={handleInputChange}
                                    value={place.accessibility}
                                    label="Place accessibility"
                                    multiline
                                    fullWidth
                                    required
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ mb: 1 }}>
                                <Typography variant="body1">Where is it?</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            id="text-input-geo-location"
                                            type="text"
                                            name="geoLocation"
                                            onChange={handleInputChange}
                                            value={place.geoLocation}
                                            label="Place coordinates"
                                            required
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            id="text-input-area"
                                            type="text"
                                            name="area"
                                            onChange={handleInputChange}
                                            value={place.area}
                                            label="Area"
                                            required
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl sx={{ minWidth: 225 }}>
                                            <InputLabel id="select-input-state-label">Select a state</InputLabel>
                                            <Select
                                                name="state"
                                                labelId="select-input-state-label"
                                                id="select-input-state"
                                                value={place.state}
                                                label="Select a state"
                                                onChange={(e) => handleLocationSelect(e)}
                                                required
                                                fullWidth
                                            >
                                                {states.map((state, index) => <MenuItem key={index} value={state.name}>{state.name}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl sx={{ minWidth: 225 }}>
                                            <InputLabel id="select-input-district-label">Select a district</InputLabel>
                                            <Select
                                                name="district"
                                                labelId="select-input-district-label"
                                                id="select-input-district"
                                                value={place.district}
                                                label="Select a district"
                                                onChange={(e) => handleLocationSelect(e)}
                                                required
                                                fullWidth
                                            >
                                                {districts.map((district, index) => <MenuItem key={index} value={district}>{district}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl sx={{ minWidth: 225 }}>
                                            <InputLabel id="select-input-district-label">Select a local body</InputLabel>
                                            <Select
                                                name="localBody"
                                                labelId="select-input-local-body-label"
                                                id="select-input-local-body"
                                                value={place.localBody}
                                                label="Select a local body"
                                                onChange={(e) => handleLocationSelect(e)}
                                                required
                                                fullWidth
                                            >
                                                {localBodies.map((localBody, index) => <MenuItem key={index} value={localBody}>{localBody}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl sx={{ minWidth: 225 }}>
                                            <InputLabel id="select-input-ward-label">Select a ward number</InputLabel>
                                            <Select
                                                name="wardNumber"
                                                labelId="select-input-ward-number-label"
                                                id="select-input-ward-number"
                                                value={place.wardNumber}
                                                label="Select a ward number"
                                                onChange={(e) => handleWardSelect(e)}
                                                required
                                                fullWidth
                                            >
                                                {wardNumbers.map((ward, index) => <MenuItem key={index} value={ward.ward_number}>Ward number {ward.ward_number}</MenuItem>)}
                                            </Select>

                                        </FormControl>
                                    </Grid>

                                </Grid>
                                <span id="select-input-ward-span">{place.wardId}</span>
                            </Box>

                        </Grid>
                        <Grid item xs={12}>
                            <Box>
                                <Typography variant="body1">What is this place about?</Typography>
                                <TextField
                                    id="text-input-description"
                                    type="text"
                                    name="description"
                                    onChange={handleInputChange}
                                    value={place.description}
                                    label="Place description"
                                    multiline
                                    fullWidth
                                    required
                                />
                                <Divider />
                            </Box>

                        </Grid>
                        <Grid item xs={12}>
                            <Box>
                                <Typography variant="body1">Is there any story associated with this place?</Typography>
                                <TextField
                                    id="text-input-story"
                                    type="text"
                                    name="story"
                                    onChange={handleInputChange}
                                    value={place.story}
                                    label="Place story"
                                    multiline
                                    fullWidth
                                />
                            </Box>

                        </Grid>
                        <Divider />

                        <Grid item xs={12} md={8}>
                            <Box>
                                {place.placeType.includes('Religious place - ') &&
                                    <>
                                        <Typography variant="body1">Deity</Typography>

                                        <FormControl sx={{ width: '100%', mb: 5 }}>
                                            <InputLabel id="input-select-deities">Deities</InputLabel>

                                            <Select
                                                labelId="demo-multiple-chip-label"
                                                id="demo-multiple-chip"
                                                multiple
                                                fullWidth
                                                value={place.deities}
                                                onChange={handleDeitySelect}
                                                input={<OutlinedInput id="select-multiple-chip" label="Select a deity" />}
                                                renderValue={(selected) => (
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        {selected.map((value) => (
                                                            <Chip key={value} label={value} />
                                                        ))}
                                                    </Box>
                                                )}
                                                MenuProps={MenuProps}
                                            >
                                                {deityList.map((deity) => (
                                                    <MenuItem
                                                        key={deity}
                                                        value={deity}
                                                    >
                                                        {deity}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <TextField
                                                id="text-input-add-deity"
                                                type="text"
                                                value={moreDieties}
                                                onChange={(e) => setMoreDieties(e.target.value)}
                                                name="add-deity"
                                                label="Add deity"
                                                fullWidth
                                            />

                                        </FormControl>
                                        <br />

                                        <Button
                                            onClick={() => updatePlace({ ...place, deities: place.deities.concat(moreDieties) })}
                                            variant="contained"
                                            color="primary"
                                            disabled={moreDieties === ''}
                                            sx={{ textTransform: "none" }}
                                            size="small"
                                        >
                                            Add
                                    </Button>
                                    <Typography variant="body1">{ place.deities.map(deity => <span>deity</span>) }</Typography>
                                    </>
                                }
                            </Box>

                        </Grid>
                        <Grid item xs={12}>
                            <Box>
                                <Typography variant="body1">Features</Typography>

                                <Button
                                    onClick={() => enableFeatures()}
                                    variant="contained"
                                    color="primary"
                                    sx={{ mb: 3, textTransform: "none" }}
                                    size="small"
                                >Get features?</Button>
                                {featuresPresent && (
                                    <>
                                        <FormControl sx={{ width: '100%', mb: 5 }}>
                                            <InputLabel id="input-select-features">Features</InputLabel>
                                            <Select
                                                labelId="input-select-features-label"
                                                id="input-select-features"
                                                multiple
                                                value={place.features}
                                                onChange={handleFeatureSelect}
                                                input={<OutlinedInput id="select-features" label="Features" />}
                                                renderValue={(selected) => (
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        {selected.map((value) => (
                                                            <Chip key={value} label={value} />
                                                        ))}
                                                    </Box>
                                                )}
                                                MenuProps={MenuProps}
                                            >
                                                {featureList.map((feature) => (
                                                    <MenuItem
                                                        key={feature}
                                                        value={feature}
                                                    >
                                                        {feature}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <TextField
                                                id="text-input-add-feature"
                                                type="text"
                                                value={moreFeatures}
                                                onChange={(e) => setMoreFeatures(e.target.value)}
                                                name="add-feature"
                                                label="Add feature"
                                            />
                                        </FormControl>
                                        <Button
                                            onClick={() => updatePlace({ ...place, features: place.features.concat(moreFeatures) })}
                                            variant="contained"
                                            color="info"
                                            disabled={moreFeatures === ''}
                                            sx={{ textTransform: "none" }}
                                            size="small"
                                        >
                                            Add
                                        </Button>
                                        <Typography variant="body1">{place.features.map(feature => <span>feature</span>)}</Typography>

                                    </>
                                )}
                            </Box>

                        </Grid>
                    </Grid>



                    <Divider />
                    <Button onClick={handleSubmit} variant="contained" color="warning" size="large" sx={{ mt: 4, textTransform: "none" }}>Submit</Button>

                </>
                
                : <p>Not logged in!</p>}

                
        </Paper> 
    )
}

export default SavePlace