import AddArticle from '../../components/stories/AddArticle';

export default function NewArticle() {

    return (
      <div>
        <AddArticle />
      </div>
  
    )
  }

  