import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../utils/auth';
import { useUser } from '../../utils/users';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.core.css';
import { useArticle } from '../../utils/articles';
import slugify from 'react-slugify';
import format from 'dateformat';
import { Button, FormControl, InputLabel, TextField, MenuItem, Select } from '@mui/material';


const ReactQuill = typeof window === 'object' ? require('react-quill') : () => false;

function EditArticle({ ...articles }) {

  const selectedArticle = { ...articles }.article;

  const auth = useAuth();
  const userContext = useUser();

  const userId = auth.userId;
  const articleContext = useArticle();

  const navigate = useNavigate(); 
  const [subCategories, setSubCategories] = useState([]);
  const [isContributor, setIsContributor] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [article, setArticle] = useState({
     body: selectedArticle.body,
    dateCreated: selectedArticle.dateCreated,
    dateUpdated: selectedArticle.dateUpdated,
    published: selectedArticle.published,
    slugName: selectedArticle.slugName,
    title: selectedArticle.title,
    category: selectedArticle.category,
    subcategory: selectedArticle.subcategory,
    image: selectedArticle.image,
    userId: selectedArticle.userId,
    firstname: selectedArticle.firstname,
    lastname: selectedArticle.lastname,
  });

const categories = [
    { name: "Culture", id: 1 },
    { name: "Cuisine", id: 2 },
    { name: "Literature", id: 3 },
    { name: "History", id: 4 },
    { name: "Lifestyle", id: 5 },
    { name: "Social", id: 6 }
];

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      ['clean']
    ],
  };
 
  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];

  const now = format().now;

  useEffect(() => {
    if (!auth.userId) return;
    if (auth.userId) {
        setIsLoggedIn(true);
    }
  }, [auth]);

  useEffect(() => {
    if (!isLoggedIn) return;
    if (isLoggedIn && isContributor) return;
    const userData = userContext.getUserData();
      setIsContributor(userData.isContributor);
  }, [userContext]);

  function addArticle(published) {
        // Add a new document with a generated id.
      if (article.body === '') {
        alert('Missing text');
        return;
      }
      if (article.title === '') {
        alert('Missing title');
        return;
      }

      if (article.category === '') {
        alert('Missing category');
        return;
      }

        articleContext.editArticle({
          body: article.body,
          dateCreated: article.dateCreated,
          dateUpdated: format(now, "ddd mm, yyyy"),
          published: published,
          slugName: slugify(article.title),
          title: article.title,
          category: article.category,
          subcategory: article.subcategory || "",
          image: article.image || "",
          userId: userId,
        }, published, selectedArticle.id)
      if (published === true) alert('Article published');
      else alert('Article Saved as draft');
      navigate('/stories/myarticles');
  };    
  
  function onInputchange(e){
        const { target: { name, value } } = e
        setArticle({...article, [name]: value});
  };

  function onSelectCategory(props) {
    setArticle({ ...article, category: props.name });
    setSubCategories(props.subcategories);
  }

  return (
    <div>
      <div className="buttonContainer">
        <Button className="publishButtons" variant="contained" color="success" onClick={()=>addArticle(true)}>Publish</Button>
        <span>&nbsp;&nbsp;</span>
        <Button className="publishButtons" variant="contained" color="warning" onClick={() => addArticle(false)}>Save as draft</Button>
      </div>
          <br />

          <FormControl sx={{ minWidth: 225, mb: 4 }}>
              <InputLabel id="select-input-state-label">Select a category</InputLabel>
              <Select
                  name="state"
                  labelId="select-input-state-label"
                  id="select-input-state"
                  value={article.category}
                  label="Select a state"
                  onChange={(e) => onSelectCategory(e.target.value)}
                  required
              >
                  {categories.map((category, index) => <MenuItem key={category} value={category.name}>{category.name}</MenuItem>)}

              </Select>
          </FormControl>
          <br />

          <TextField
              id="text-input-title"
              type="text"
              name="title"
              onChange={(e) => onInputchange(e)}
              value={article.title}
              label="Enter title"
              required
              sx={{ mb: 4 }}
              fullWidth
          />
          <TextField
              id="text-input-img-src"
              type="text"
              name="image-source"
              onChange={(e) => onInputchange(e)}
              value={article.image}
              label="Enter image source"
              sx={{ mb: 4 }}
              fullWidth
          />
          <ReactQuill value={article.body}
              className="editorContainer"
              onChange={(e) => setArticle({ ...article, body: e })}
              theme="snow"
              modules={modules}
              formats={formats}
          />
 
      <style jsx global>{`
        .publishButtons{
          width: 10rem;
          float:right;
          margin: auto 2rem;
        }
        .buttonContainer{
          margin: 1rem auto 2rem auto;
        }
        .category{
          width: 15rem;
          float: right;
          margin: 1rem 0 1rem 1rem;
        }
        .dropdown{
          margin: 0 1rem 1rem 0;
          float: left;
        }
       
      `}</style>

    </div>

  )
}

export default EditArticle

