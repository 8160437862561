import * as React from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

export default function WordCategories({ category, categoryNepali, setCategory, setShowWordContainer, words, setWords, setIndex }) {

    const handleClick = () => {
        setIndex(0);
        setCategory(category);
        setWords(words);
        setShowWordContainer(true);
    }

    return (
        <Card
            sx={{
                maxWidth: 225,
                padding: '10px 10px 10px 10px',
                mb: '5rem',
                backgroundColor: '#eee',
                border: '1px solid #036f11',
                '&:hover': {
                    backgroundColor: '#213785',
                    color: '#fff'
                }
            }}
            onClick={handleClick}
        >
            <Typography
                gutterBottom
                variant="body1"
                letterSpacing={2}
                fontWeight={'700'}
                align='justify'
                fontSize={'1.3rem'}
                sx={{ textAlign: 'center' }}
            >
                {category}
            </Typography>
            <Typography gutterBottom
                variant="body1"
                letterSpacing={2}
                fontWeight={'700'}
                fontSize={'1.3rem'}
                align='justify'
                sx={{ textAlign: 'center', color: '#A9A9A9' }}
            >
                {categoryNepali}
            </Typography>
        </Card>

    );
}
