import React, { useState, useEffect, useCallback } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvent, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet.gridlayer.googlemutant';
import { Link } from 'react-router-dom';

// Fix for default marker icon issue in Webpack
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import { extractLatLng } from '../../helpers/helperFunctions';

const defaultIcon = L.icon({
    iconUrl: markerIcon,
    iconRetinaUrl: markerIcon2x,
    shadowUrl: markerShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
});

L.Marker.prototype.options.icon = defaultIcon;

const fetchPlaces = async (bounds) => {
    const { _southWest: sw, _northEast: ne } = bounds;
    const response = await fetch(`https://places-nepal-290be81f7552.herokuapp.com/api/places/map?swLat=${sw.lat}&swLon=${sw.lng}&neLat=${ne.lat}&neLon=${ne.lng}`);
    const data = await response.json();
    return data;
};

const MapEventHandler = ({ onBoundsChange }) => {
    useMapEvent({
        moveend: (event) => {
            const map = event.target;
            onBoundsChange(map.getBounds());
        },
    });

    return null;
};

const CurrentLocationButton = () => {
    const map = useMap();

    const handleLocationClick = () => {
        map.locate({
            setView: true,
            maxZoom: 16
        });
    };

    useMapEvent('locationfound', (event) => {
        L.marker(event.latlng).addTo(map)
            .bindPopup('You are here')
            .openPopup();
    });

    return (
        <button
            style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                zIndex: 1000,
                background: '#036f11',
                borderRadius: '0.4rem',
                padding: '5px 10px',
                color: '#ffffff'
            }}
            onClick={handleLocationClick}
        >
            Find My Location
        </button>
    );
};


const MapView = () => {
    const [places, setPlaces] = useState([]);
    const [mapBounds, setMapBounds] = useState(null);

    const handleBoundsChange = useCallback(async (bounds) => {
        const data = await fetchPlaces(bounds);
        setPlaces(data);
    }, []);

    useEffect(() => {
        if (mapBounds) {
            handleBoundsChange(mapBounds);
        }
    }, [mapBounds, handleBoundsChange]);

    return (
        <MapContainer
            center={[27.7172, 85.3240]}
            zoom={10}
            style={{ height: '80vh', width: '100%', marginTop: '3rem', paddingLeft: '2rem'}}
            minZoom={6}
            doubleClickZoom={false} // Disable double click zoom
            scrollWheelZoom={false} // Disable scroll wheel zoom
            touchZoom={false} // Disable pinch zoom on touch devices
            zoomControl={true} // Disable default zoom control
        >
            <TileLayer
                url="https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
                attribution='&copy; <a href="https://maps.google.com">Google Maps</a>'
            />
            <MapEventHandler onBoundsChange={setMapBounds} />
            {places.map((place) => (
                <Marker key={place.id} position={extractLatLng(place.geoLocation)}>
                    <Popup>
                        <strong>{place.placeName}</strong><br />
                        {place.placeType.join(' | ')}
                        <br />
                        <Link to={`/place/${place.id}`} style={{ textDecoration: 'underline' }}>
                            Visit place
                        </Link>
                    </Popup>
                </Marker>
            ))}
            <CurrentLocationButton />
        </MapContainer>
    );

};

export default MapView;
