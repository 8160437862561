import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.core.css';
import { Paper, Typography } from '@mui/material';
import {
    Divider,
    CardMedia
} from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import parse from 'html-react-parser';

function ArticleTemplate(props) {  
  const article = {
    body: props.data.body,
    dateCreated: props.data.dateCreated,
    dateUpdated: props.data.dateUpdated,
    published: props.data.published,
    slugName: props.data.slugName,
    title: props.data.title,
    category: props.data.category,
    subcategory: props.data.subcategory,
    image: props.data.image,
    userId: props.data.userId,
    firstname: props.data.firstName,
    lastname: props.data.lastName,
  };

  function getDate(props) {
    const date = new Date(props).getUTCDate();
    const month = new Date(props).getUTCMonth();
    const year = new Date(props).getUTCFullYear();

    return `${date}/${month}/${year}`
  }

    //getImageFromS3(imageKey)
    //    .then(imageData => {
    //        // Use the image data (e.g., display in an <img> tag)
    //        const imageUrl = URL.createObjectURL(new Blob([imageData], { type: 'image/jpeg' }));
    //        console.log('Image URL:', imageUrl);
    //    })
    //    .catch(error => {
    //        console.error('Error fetching image:', error);
    //    });


    return (
        <Paper>
            <Grid container spacing={2} sx={{ p: 0 }}>
                <Container fixed sx={{ pl: 0, pr: 0 }}>
                    <Typography variant='h1' sx={{ fontWeight: '800', fontSize: '36px', color: '#004747' }} >{article.title}
                    </Typography>
                    <Typography variant='span' sx={{ mb: 4 }}>{getDate(article.dateUpdated)}</Typography>
                    <Typography variant='body1' sx={{ mb: 2, mt: 2 }}><strong>Category:</strong> {article.category}</Typography>
                    <Divider />

                    <Box>
                        <Typography variant='body1' sx={{ lineHeight: 2, fontSize: '20px' }}>
                            {parse(`${article.body}`)}
                        </Typography>
                    </Box>
                </Container>
            </Grid>
        </Paper>
    )
}

//                    {article.image && (
//                        <CardMedia
//                            component="img"
//                            height="400"
//                            image={article.image}
//                        />
//                    )}

export default ArticleTemplate;