import * as React from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

export default function PlacesCard({ placeName, placeNameNepali, placeType, link }) {
    return (
        <Link to={link} style={{ textDecoration: 'none' }}>
            <Card sx={{ maxWidth: 225, padding: '30px 10px 10px 10px', mb: 4, backgroundColor: '#eee', border: '1px solid #036f11', '&:hover': { backgroundColor: '#213785', color: '#fff' } }} >
                <Typography
                    gutterBottom
                    variant="body1"
                    letterSpacing={2}
                    fontWeight={'700'}
                    align='justify'
                    sx={{ textAlign: 'center' }}
                >
                    {placeName} [{ placeNameNepali }]
                </Typography>
                <Typography variant='span' sx={{ mb: 4 }}>{placeType.join(' | ')}</Typography>
            </Card>
        </Link>

    );
}
