import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getStateInfo } from '../api/getLocations';
import PlaceCard from '../components/Cards/PlaceCard';
import { Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import {
    Divider, ListItem, Card,
} from '@mui/material';
import { Parser } from 'html-to-react'
import AdBox from '../components/AdBox/AdBox';

const BrowseDistricts = () => {
    const [stateDetails, setStateDetails] = useState({});
    const { state } = useParams();
    const path = `/browse/${state}`
    const htmlParser = new Parser();

    useEffect(() => {
        getStateInfo(state)
            .then(response => response.json())
            .then(data => setStateDetails(data))
            .catch(e => console.error(e.message));
    }, [state]);

    return (
        <Paper variant='outlined'>
            <Grid container sx={{ mb: 5 }}>
                <Grid item xs={12} md={8}>
                    <Grid container>
                        <Grid item>
                            <Typography variant='h1' sx={{ fontWeight: '800', fontSize: '30px', color: '#004747' }} >
                                {stateDetails.name} [ {stateDetails.nameNepali} ]
                            </Typography>
                            <Typography variant='body1' sx={{ mb: 4 }}><strong>Headquarter</strong>: {stateDetails.headquarter} [ {stateDetails.headquarterNepali} ]</Typography>
                            <Divider />
                        </Grid>
                        <Grid item sx={{ mb: 5 }}>
                            <Container fixed>
                                <Typography variant='h4' sx={{ mb: 2, mt: 2, fontSize: '20px' }}>Select a district</Typography>
                                <Grid container spacing={4}>
                                    {stateDetails.districts && stateDetails.districts.map((district, index) =>
                                        <Grid item xs={'auto'} key={index}>
                                            <PlaceCard placeName={district} link={`${path}/${district}`} />
                                        </Grid>
                                    )}
                                </Grid>
                            </Container>
                        </Grid>
                        <Grid item>
                            {stateDetails.description && (
                                <Card fixed elevation={10}>
                                    <Typography variant='body1' sx={{ fontWeight: '600', fontSize: '20px', mb: 2 }}>About {stateDetails.name}</Typography>
                                    <Typography variant='body1' sx={{ fontWeight: '400', fontSize: '1rem' }}>{htmlParser.parse(stateDetails.description)}</Typography>
                                </Card>
                            )}
                        </Grid>
                     </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    {state.majorAttractions && state.majorAttractions.length > 0 && (
                        <>
                            <Card elevation={10} sx={{ mb: 2 }}>
                                <Typography variant='body1' sx={{ mb: 2, mt: 2 }}>
                                    <strong>Top attractions:</strong> {state.majorAttractions
                                        .map((attraction) =>
                                            <ListItem>{attraction}</ListItem>)}
                                </Typography>
                            </Card>
                            <Divider />
                        </>
                    )}
                    <AdBox/>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default BrowseDistricts
