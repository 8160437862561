const SocialButton = ({ platform, size, backgroundColor, link }) => {
    const buttonStyle = {
        borderRadius: '5%',
        width: size === 'large' ? '3rem' : '1.9rem',
        height: size === 'large' ? '3rem' : '1.9rem',
        background: backgroundColor,
        padding: size === 'large' ? '0.9rem 1.5rem' : '0.5rem 1rem',
        fontSize: size === 'large' ? '1rem' : '0.8rem',
        textDecoration: 'none',
        color: 'white',
        fontWeight: '800',
        marginLeft: 5,
        marginTop: 5
    };

    return (
        <a href={link} target="_blank" rel="noopener noreferrer" style={buttonStyle}>
            {platform}
        </a>
    );
};

export default SocialButton;