import React, { useState, useEffect } from 'react';

import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.core.css';

import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../utils/auth';
import { useUser } from '../../utils/users';

import { useArticle } from '../../utils/articles';
import slugify from 'react-slugify';
import { Button, FormControl, InputLabel, TextField, MenuItem, Select, Paper, Typography, Box, Divider } from '@mui/material';

import format from 'dateformat';

const ReactQuill = typeof window === 'object' ? require('react-quill') : () => false;

function AddArticle() {

  const auth = useAuth();
  const userContext = useUser();

  const userId = auth.userId;
  const articleContext = useArticle();

    const navigate = useNavigate();
  const [isContributor, setIsContributor] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

    const categories = [
        { name: "Culture", id: 1 },
        { name: "Cuisine", id: 2 },
        { name: "Literature", id: 3 },
        { name: "History", id: 4 },
        { name: "Lifestyle", id: 5 },
        { name: "Social", id: 6 }
    ];

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image', 'video', 'formula'],
      ['clean']
    ],
  };
 
  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link',
  ];

  const [article, setArticle] = useState({
      body: '',
      dateCreated: '',
      dateUpdated: '',
      published: false,
      slugName: '',
      title: '',
      category: '',
      subcategory: '',
      image: '',
      userId: '',
      firstname: '',
      lastname: '',
  });

  const now = format().now;

  useEffect(() => {
    if (!auth.userId) return;
    if (auth.userId) {
        setIsLoggedIn(true);
    }
  }, [auth]);

  useEffect(() => {
    if (!isLoggedIn) return;
    if (isLoggedIn && isContributor) return;
    const userData = userContext.getUserData();
      setIsContributor(userData.isContributor);
  }, [userContext]);

  function addArticle(published) {
        // Add a new document with a generated id.
      if (article.body === '') {
        alert('Missing text');
        return;
      }
      if (article.title === '') {
        alert('Missing title');
        return;
      }

      if (article.category === '') {
        alert('Missing category');
        return;
      }
    
    articleContext.addArticle({
      body: article.body,
      dateCreated: format(now, "isoDateTime"),
      dateUpdated: format(now, "isoDateTime"),
      published: published,
      slugName: slugify(`${article.category}${article.subcategory}${article.title}`),
      title: article.title,
      category: article.category,
      subcategory: article.subcategory || "",
      image: article.image || "",
      userId: userId,
    }, published);

      if (published === true) alert('Article published');
      else alert('Article Saved as draft');
      navigate('/stories/myarticles');
  };    
  
  function onInputchange(e){
        const { target: { name, value } } = e
        setArticle({...article, [name]: value});
  };

  function onSelectCategory(event) {
    setArticle({ ...article, category: event.target.value });
  }

  return (
      <Paper>
          {isContributor ? (
              <>
                  <Box className="buttonContainer">
                      <Button className="publishButtons" variant="contained" color="success" onClick={() => addArticle(true)}>Publish</Button>
                      <span>&nbsp;&nbsp;</span>
                      <Button className="publishButtons" variant="contained" color="warning" onClick={() => addArticle(false)}>Save as draft</Button>
                  </Box>
                  <br />
                  <FormControl sx={{ minWidth: 225, mb: 4 }}>
                      <InputLabel id="select-input-category-label">Select a category</InputLabel>
                      <Select
                          name="categories"
                          labelId="select-input-category-label"
                          id="select-input-category"
                          value={article.category}
                          label="Select a category"
                          onChange={(e) => onSelectCategory(e)}
                          required
                      >
                          {categories.map((category, index) => <MenuItem key={category.id} value={category.name}>{category.name}</MenuItem>)}

                      </Select>
                  </FormControl>
                  <br />
                  <TextField
                      id="text-input-title"
                      type="text"
                      name="title"
                      onChange={(e) => onInputchange(e)}
                      value={article.title}
                      label="Enter title"
                      required
                      sx={{ mb: 4 }}
                      fullWidth
                  />
                  <TextField
                      id="text-input-img-src"
                      type="text"
                      name="image"
                      onChange={(e) => onInputchange(e)}
                      value={article.image}
                      label="Enter image source"
                      sx={{ mb: 4 }}
                      fullWidth
                  />
                  <ReactQuill value={article.body}
                      className="editorContainer"
                      onChange={(e) => setArticle({ ...article, body: e })}
                      theme="snow"
                      modules={modules}
                      formats={formats}
                      placeholder="Start writing your article ..."
                      style={{ minHeight: '500px' }}
                  />
             </>
          ) : (
              <Typography variant='body1'>You are not logged in. Log in first</Typography>
          )}
      
    </Paper>
  )
}
export default AddArticle

