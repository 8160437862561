import * as React from 'react';
import Card from '@mui/material/Card';

export default function GygAdCard() {
    return (
        <Card
            sx={{
                maxWidth: 400,
                minHeight: 500,
                color: 'black',
                marginTop: 0
            }}
        >
            <div data-gyg-widget="auto" data-gyg-partner-id="HAI6BK9"></div>
        </Card>
    );
};