// PlaceContext.js
import React, { createContext, useContext, useState } from 'react';

// Define the shape of the context state
const initialPlaceState = {
  placeId: '',
  placeName: '',
  placeNameNepali: '',
  placeWebsite: '',
  area: '',
  geoLocation: '',
  accessibility: '',
  wardNumber: '',
  localBody: '',
  district: '',
  state: '',
  placeType: [],
  description: '',
  placePhotos: [],
  placeVideos: [],
  phoneNumber: '',
  emailAddress: '',
  story: '',
  wardId: '',
  features: [],
  deities: []
};

// Create the context
const PlaceContext = createContext(initialPlaceState);

// Create a provider component
export const PlaceProvider = ({ children }) => {
  const [place, setPlace] = useState(initialPlaceState);

  // Function to update place details
  const updatePlace = (newDetails) => {
    setPlace((prevState) => ({
      ...prevState,
      ...newDetails
    }));
  };

  return (
    <PlaceContext.Provider value={{ place, updatePlace }}>
      {children}
    </PlaceContext.Provider>
  );
};

// Custom hook to use the PlaceContext
export const usePlaceContext = () => {
  const context = useContext(PlaceContext);
  if (!context) {
    throw new Error('usePlaceContext must be used within a PlaceProvider');
  }
  return context;
};