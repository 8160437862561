import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';

import Home from './pages/HomePage/Home.jsx';
import BrowseDistricts from './pages/BrowseDistricts.jsx';
import BrowseStates from './pages/BrowseStates.jsx';
import BrowsePlaces from './pages/BrowsePlaces.jsx';
import Stories from './pages/Stories.jsx';
import PlaceDetails from './pages/PlaceDetails.jsx';
import StoryDetails from './pages/StoryDetails.jsx';
import SavePlace from './pages/SavePlace.jsx';
import NoPageFound from './pages/NoPageFound.jsx';

import About from './pages/About.jsx';
import PlacesMap from './pages/PlacesMap/PlacesMap.jsx';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Toast from './components/Toast/Toast';
import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';
import UpdatePlace from './pages/UpdatePlace';
import { ProvideAuth } from './utils/auth'
import { ProvideUser } from './utils/users';
import { ProvideArticle } from './utils/articles';
import { PlaceProvider } from './context/PlaceContext';
import { ToastProvider } from './context/ToastContext';
import NewArticle from './pages/stories/NewArticle';
import MyArticles from './pages/stories/MyArticles';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Privacy from './pages/Privacy';
import LearnNepali from './pages/LearnNepali/LearnNepali';


function App() {

  return (
      <ProvideAuth>
          <ProvideUser>
              <ProvideArticle>
                  <PlaceProvider>
                      <ToastProvider>
                          <div className="App">
                              <BrowserRouter>
                                  <Toast/>
                                  <Grid container>
                                      <Grid item xs={12}>
                                          <Navbar />
                                      </Grid>
                                      <Grid item xs={12} sx={{ minHeight: '80vh' }}>
                                          <Item>
                                              <Routes>
                                                  <Route path="/" element={<Home />} />
                                                  <Route path="/place/:id" element={<PlaceDetails />} />
                                                  <Route path="/browse/places" element={<BrowseStates />} />
                                                  <Route path="/browse/places/map" element={<PlacesMap />} />
                                                  <Route path="/browse/:state" element={<BrowseDistricts />} />
                                                  <Route path="/browse/:state/:district" element={<BrowsePlaces />} />
                                                  <Route path="/browse/:state/:district/:place" element={<PlaceDetails />} />
                                                  <Route path="/place/update/:id" element={<UpdatePlace />} />
                                                  <Route path="/place/save" element={<SavePlace />} />
                                                  <Route path="/about" element={<About />} />
                                                  <Route path="/browse/stories" element={<Stories />} />
                                                  <Route path="/stories/:storyId" element={<StoryDetails />} />
                                                  <Route path="/stories/addarticle" element={<NewArticle />} />
                                                  <Route path="/stories/myarticles" element={<MyArticles />} />
                                                  <Route path="/signin" element={<SignIn />} />
                                                  <Route path="/signup" element={<SignUp />} />
                                                  <Route path="/privacy" element={<Privacy />} />
                                                  <Route path="/learnnepali" element={<LearnNepali />} />\
                                                  <Route path="*" element={<NoPageFound />} />

                                              </Routes>
                                          </Item>
                                      </Grid>
                                      <Grid item xs={12}>
                                          <Item><Footer /></Item>
                                      </Grid>
                                  </Grid>
                              </BrowserRouter>
                          </div>
                      </ToastProvider>
                  </PlaceProvider>
              </ProvideArticle>
          </ProvideUser>
      </ProvideAuth>
  );
}

export default App;
