import { Paper, Typography } from '@mui/material';

export default function About() {

    return (
        <Paper variant='outlined'>
            <Typography variant='h1' sx={{ fontSize: '36px', mb:4, color: '#004747' }}>About us</Typography>
            <Typography variant='body1'>
                The idea behind Attractions Nepal is simple - deeper exploration of Nepal and sharing its stories with the world.
            </Typography>
            <br/>
            <Typography variant='body1'>
                There is no doubt that Nepal is a beautiful country with a rich history, culture, cuisine, biodiversity, and geodiversity. There are monuments that are from 4th centuries collecting the stories from different eras. There are more than 100 distinct cultures and each of the cultures have their own distinct cuisine, music, dance, artworks, and attire.
            </Typography>
            <br />
            <Typography variant='body1'>
                When Nepal says that it is rich in biodiversity, a supporting statement to that is that nearly 25 percent of Nepal's landmass is designated as protected area. There are ten national parks, three wildlife reserves and five conservation areas.
            </Typography>
            <br />
            <Typography variant='body1'>
                When Nepal says that it is rich in geodiversity, that is supported by the fact that when you travel 336 km from Chitwan National park to Langtang National park, you will have started at an altitude of 60m all the way to 5000m and will experience a plethora of distinct experiences. That 5000m is not the peak but the livable place in the Himalayas.
            </Typography>
            <br />
            <Typography variant='body1'>
                You will find its own distinct tastes in bara, sel roti, thakali set, momo, thukpa, chatamari, tsampa, bhakka, to sekuwa. You will find a distinct groove in Maruni, Lakhey, Sorathi, Chandi, Ghatu, Deuda, chyabrung, and lathi dance. You will experience the difference in celebrations of jatras in Kathmandu valley, Chhat puja, and holi in Terai, Losar, Teej, Maha Shivaratri, Buddha jayanti, Dashain and Tihar.
            </Typography>
            <br />
            <Typography variant='body1'>
                Whether you want to pump up your adrenaline, relax in Nature, get your feets moving, experience inner happiness, wowed by the Himalayas or lakes or waterfalls or ancient monuments, whether you want to tease your palate, whether you want to experience five star/seven star luxury, you will get everything in Nepal.
            </Typography>
            <br />
            <Typography variant='body1'>
                The people of Nepal are hard working and welcoming. Nepal can be and should be one of the top touristic destination. You don't just visit Nepal, you experience Nepal. Thus, Attractions Nepal was born with a mission of sharing the stories of Nepal and helping world citizens experience and not just visit Nepal.
            </Typography>
        </Paper>

    )
}
