import { useState } from 'react';
import { Paper, Typography, Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import AdBox from '../../components/AdBox/AdBox';
import wordCategories from './wordCategories';
import WordCategoriesCard from '../../components/Cards/WordCategoriesCard';
import WordCard from '../../components/Cards/WordCard';

const LearnNepali = () => {
    const [wordCategory, setWordCategory] = useState('');
    const [showWordContainer, setShowWordContainer] = useState(false);
    const [wordArray, setWordArray] = useState([]);
    const [index, setIndex] = useState(0);

    return (
        <Paper variant='outlined'>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    <Grid container>
                        <Typography variant='h1' sx={{ fontWeight: '800', fontSize: '36px', color: '#004747' }} >Learn Nepali</Typography>
                        <Typography variant='body1' sx={{ mb: 4 }}>Learn Nepali vocabulary with us. Click on the category to get the words and start learning</Typography>
                        <Grid container spacing={4}>
                            {Object.entries(wordCategories).map((category) => {
                                return (
                                    <Grid item>
                                        <WordCategoriesCard
                                            category={category[0]}
                                            categoryNepali={category[1].categoryNepali}
                                            setCategory={setWordCategory}
                                            setShowWordContainer={setShowWordContainer}
                                            words={category[1].words}
                                            setWords={setWordArray}
                                            setIndex={setIndex}
                                        />
                                    </Grid>
                                )
                            }   
                            )}
                        </Grid>
                        {(wordCategory !== '' && showWordContainer) && (
                            <WordCard
                                nepaliWord={wordArray[index].nepali}
                                englishWord={wordArray[index].english}
                                index={index}
                                setIndex={setIndex}
                                numberOfWords={wordArray.length}
                                setShowWordContainer={setShowWordContainer}
                                showWordContainer={showWordContainer}
                            />
                        )}
                    </Grid>
                    <Divider sx={{ mb: 5 }} />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <AdBox />
                </Grid>
            </Grid>
        </Paper>
    )
}

export default LearnNepali
