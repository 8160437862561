import * as React from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

export default function ErrorSummary({ errors }) {
    const errorMessages = {
        placeName: 'Place name is required.',
        placeNameNepali: 'Place name in Nepali is required.',
        geoLocation: 'Geo location is required.',
        accessibility: 'Accessibility is required.',
        placeType: 'At least one place type is required.',
        description: 'Description is required.',
        wardId: 'Ward ID is required.'
    };
    return (
        <Card sx={{ backgroundColor: '#D5C28F', color: '#000000', padding: '2rem 1rem' }} >
            <Typography
                gutterBottom
                variant="body1"
                fontWeight={'700'}
            >
                Fix the following errors:
            </Typography>
            <ul>
                {errors.map((error, index) => (
                    <li key={index}>
                        <Typography
                            gutterBottom
                            variant="body1"
                            fontWeight={'400'}
                        >
                            {errorMessages[error]}
                        </Typography>
                    </li>
                ))}
            </ul>
        </Card>
    );
}
