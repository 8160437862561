import {useAuth} from '.././utils/auth';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';

import { useState } from 'react';
import { 
    Button, 
    Card, 
    TextField, 
    CardActionArea, 
    CardActions, 
    Typography, 
    CardContent,
    Grid,
 } from '@mui/material';

export default function SignIn() {
    const auth = useAuth();
    const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const signIn = () => {

    auth.signin(email, password)
      .then(() => {  
          navigate('/');
        window.alert('Successfully signed in');
      })
      .catch((error) => {
          setError(error.message);
    });
  };

  return (
    <Card raised> 
      <CardActionArea>
        <Typography gutterBottom variant="h5" component="h3">
            Sign In
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8} lg={6}>
            <CardContent>
              <TextField
                    id="email-signin-textfield"
                    label="Enter your email"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    type={"email"}
                    sx={{mb: 4}}
              />
              <br></br>
              <TextField
                  error={error}
                  id="password-signin-textfield"
                  label="Enter your password"
                  helperText={error}
                  variant="outlined"
                  value={password} 
                  onChange={(e) => setPassword(e.target.value)} 
                  required
                  type={"password"}
              />
            </CardContent>
          </Grid>
        </Grid>
      </CardActionArea>
      <CardActions>
          <Button variant="contained" size="medium" onClick={()=>signIn()}>
              Sign In
          </Button>
          <Link href="/reset">Forgot Password?</Link>
            <span>&nbsp;|&nbsp;</span>
          <Link to="/signup">Register</Link>
      </CardActions>
    </Card>
  );
}