import React, { useState,  useEffect} from 'react';
import { getPlaceDetails } from './../api/getLocations';
import { useAuth } from './../utils/auth';
import { useUser } from './../utils/users';
import { usePlaceContext } from './../context/PlaceContext';
import { useToastContext } from './../context/ToastContext';
import { useNavigate, useParams } from 'react-router-dom';
import { useJsApiLoader, GoogleMap, Marker } from '@react-google-maps/api';
import { Paper, Typography } from '@mui/material';
import {
    Card,
    Divider,
    ListItem,
    ListItemText,
    ListItemIcon
} from '@mui/material';
import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LaunchIcon from '@mui/icons-material/Launch';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import AdBox from '../components/AdBox/AdBox';
import parse from 'html-react-parser';

const PlaceDetails = () => {
    const navigate = useNavigate();
    const auth = useAuth();
    const userContext = useUser();
    const { place, updatePlace } = usePlaceContext();
    const { showToast } = useToastContext();

    const [location, setLocation] = useState({
        lat: 0,
        lng: 0
    });
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isContributor, setIsContributor] = useState(false);
    const { id } = useParams();


    const RenderMap = () => {
        const { isLoaded } = useJsApiLoader({ googleMapsApiKey: 'AIzaSyC4U92D3IaCRIAi75PRStPg_aahlqDlyQk' });
        if (!isLoaded) {
            return null;
        }
        // google is guaranteed to be defined now
        return (
            <GoogleMap
                center={location}
                zoom={15}
                mapContainerStyle={{ width: '100%', height: '100%'}}
                options={{
                    zoomControl: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                    fullScreenControl: false,
                }}
            >
                <Marker position={location} />
            </GoogleMap>
            )
    }

    const handleUpdateClick = () => {
        navigate(`/place/update/${id}`)
    }

    useEffect(() => {
        if (!auth.userId) return;
        if (auth.userId) {
            setIsLoggedIn(true);
        }
    }, [auth]);

    useEffect(() => {
        if (!isLoggedIn) return;
        if (isLoggedIn && isContributor) return;
        const userData = userContext.getUserData();
        setIsContributor(userData.isContributor);
    }, [userContext, isContributor, isLoggedIn]);

    useEffect(() => {
        getPlaceDetails(id)
            .then(response => response.json())
            .then(data => {
                updatePlace({
                    ...place,
                    placeId: data.placeId,
                    placeName: data.placeName,
                    placeNameNepali: data.placeNameNepali,
                    placeWebsite: data.placeWebsite,
                    area: data.area,
                    geoLocation: data.geoLocation,
                    accessibility: data.accessibility,
                    wardId: data.wardId,
                    wardNumber: data.wardNumber,
                    localBody: data.localBody,
                    district: data.district,
                    state: data.state,
                    placeType: data.placeType,
                    description: data.description,
                    placePhotos: data.placePhotos,
                    phoneNumber: data.phoneNumber,
                    emailAddress: data.emailAddress,
                    story: data.story,
                    features: data.features,
                    deities: data.deities
                })
                const [latitude, longitude] = data.geoLocation.split(', ');
                setLocation({ ...location, lat: parseFloat(latitude), lng: parseFloat(longitude) })
            })
            .catch(e => {
                showToast('Error getting this place details!', 'error');
            })
    }, []);

    return (
        <Paper variant='outlined'>
            <Grid container spacing={2} sx={{ p: 0 }}>
                <Grid item xs={12} md={8} sx={{ pl: 0, pr: 0 }}>
                    <Container fixed sx={{ pl: 0, pr: 0 }}>
                        <Typography variant='h1' sx={{ fontWeight: '800', fontSize: '28px', mb: 1, color: '#004747' }} >{place.placeName} [ {place.placeNameNepali} ]
                            {isContributor && (<Button onClick={handleUpdateClick}>Update</Button>)}
                        </Typography>
                        <Typography variant='body1' sx={{ mb: 1 }} ><strong>Location:</strong> {place.localBody}
                        </Typography>
                        <Typography variant='body1' sx={{ mb: 1 }}><strong>Place type:</strong> {place.placeType.join(' | ')}</Typography>
                        {place.deities.length > 0 && (
                            <Typography variant='body1' sx={{ mb: 2 }}><strong>Deities:</strong> {place.deities.join(' | ')}</Typography>
                        )}
                        <Divider />
                        {place.placePhotos.length > 0 && (
                            <Card elevation={11} sx={{ mb: 4 }}>
                                <img src='https://media.istockphoto.com/id/637696304/photo/patan.jpg?s=612x612&w=0&k=20&c=-53aSTGBGoOOqX5aoC3Hs1jhZ527v3Id_xOawHHVPpg=' width="100%" />
                            </Card>
                        )}
                        <Box className="description-container" sx={{ mb: 4 }}>
                            <Typography variant='h3' sx={{ mb: 4, fontSize: '25px' }}>Overview</Typography>
                            <Typography variant='body1'>{parse(place.description)}</Typography>
                        </Box>
                        <Divider />


                        <Box className="description-container" sx={{ mb: 4 }}>
                            <Typography variant='h3' sx={{ mb: 4, fontSize: '25px' }}>Location</Typography>
                            <Box sx={{ height: '50vh', width: '100%' }}>
                                <RenderMap />
                            </Box>
                            <br />
                            <a href={'https://maps.google.com?q=' + location.lat + ',' + location.lng}><Typography variant="body1">Open in maps <LaunchIcon /> </Typography></a>
                        </Box>
                        <Divider />

                        <Box className="description-container" sx={{ mb: 4 }}>
                            <Typography variant='h3' sx={{ mb: 4, fontSize: '25px' }}>Accessibility</Typography>
                            <Typography variant='body1'>{parse(place.accessibility)}</Typography>
                        </Box>
                        <Divider />
                        <Box className="story-container" sx={{ mb: 4 }}>
                            <Typography variant='h3' sx={{ mb: 4, fontSize: '25px' }}>Story</Typography>
                            <Typography variant='body1'>{(place.story === '') ? 'No story associated with this place is found. Send a story to us to put here.' : parse(place.story)}</Typography>
                        </Box>
                    </Container>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Grid container spacing={2}>
                        {(
                        place.phoneNumber !== null ||
                        place.phoneNumber !== '' ||
                        place.emailAddress != null ||
                        place.emailAddress !== '' ||
                        place.placeWebsite != null ||
                        place.placeWebsite !== ''
                        ) && (
                            <Grid item xs={12}>
                                <Card elevation={10}>
                                    {place.phoneNumber !== null && place.phoneNumber !== 0 && place.phoneNumber !== '' &&
                                        (
                                        <>
                                            <Typography variant='body1'>
                                                <PhoneIcon />
                                                {' '}
                                                {place.phoneNumber}
                                            </Typography>
                                        </>
                                        )
                                    }
                                    {place.emailAddress != null && place.emailAddress !== '' &&
                                        (
                                        <>
                                            <Typography variant='body1' sx={{ lineHeight: 3 }}><EmailIcon />{' '}{place.emailAddress.toLowerCase()}</Typography>
                                        </>
                                        )
                                    }
                                    {place.placeWebsite != null && place.placeWebsite !== '' &&
                                        <Typography variant='body1' sx={{ textDecoration: 'none' }}><Link href={place.placeWebsite}><LaunchIcon />{' '}{place.placeWebsite}</Link></Typography>
                                    }
                                </Card>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Card elevation={5}>
                                <Typography variant='body1' sx={{ fontSize: '1.5rem' }}>Features</Typography>
                                {place.features.map((feature) => (
                                    <ListItem key={feature}>
                                        <ListItemIcon>
                                            <ArrowForwardTwoToneIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={feature}
                                            sx={{
                                                color: '#000',
                                                listStyleType: '->'
                                            }}
                                        />
                                    </ListItem>
                                ))}
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <AdBox/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default PlaceDetails