export function extractLatLng(locationString) {
    // Split the string by comma and trim whitespace
    const parts = locationString.split(',').map(part => part.trim());

    // Ensure we have exactly two parts
    if (parts.length !== 2) {
        throw new Error('Invalid location string format. Should be "latitude, longitude"');
    }

    // Parse latitude and longitude to float values and return as an array
    return parts.map(parseFloat);
}