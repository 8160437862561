import React from 'react';
import { states } from '../constants/states';
import './BrowseStates.css';
import PlaceCard from '../components/Cards/PlaceCard';
import { Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import AdBox from '../components/AdBox/AdBox';

const BrowseStates = () => {
    const path = `/browse`

    return (
        <Paper variant='outlined'>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8} sx={{ p: 0 }}>
                    <Container>
                        <Typography variant='h1' sx={{ fontWeight: '800', fontSize: '30px', mb: 2, color: '#004747' }} >Browse places by state and district</Typography>
                        <Typography variant='body1' sx={{ mb: 4 }} >Select a state and then district to find places within a state and a district</Typography>
                        <Grid container spacing={4}>
                            {states.map((state, index) =>
                                <Grid item xs={'auto'} key={index}>
                                    <PlaceCard placeName={state.name.replace('Province', '')} link={`${path}/${state.name}`} />
                                </Grid>
                            )}
                        </Grid>
                    </Container>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <AdBox/>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default BrowseStates