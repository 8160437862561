import * as React from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

export default function PlaceCard({ placeName, link }) {
    return (
        <Link to={link} style={{ textDecoration: 'none' }}>
            <Card sx={{ maxWidth: 150, width: 150, height: 30, maxHeight: 30, backgroundColor: '#eee', color: 'black', border: '1px solid #036f11', padding: '20px 10px 10px 10px', '&:hover': { backgroundColor: '#213785', color: '#ffffff'} }} >
                <Typography
                    gutterBottom
                    variant="body1"
                    letterSpacing={2}
                    fontWeight={'700'}
                    align='justify'
                    sx={{ textAlign: 'center' }}
                >
                    {placeName}
                </Typography>
            </Card>
        </Link>

    );
}
