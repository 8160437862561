import React from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import mountain from '../../../assets/mountain.jpg';
import Searchbar from '../../../components/SearchBar/Searchbar';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';

const HeaderSection = () => {
    return (
        <Grid container sx={{ p: 2, pt: 0 }}>
            <Grid item xs={12} md={12} key="home-heading" sx={{ minHeight: '80vh', backgroundImage: `linear-gradient(rgb(0, 0, 0, 0.9), rgb(255,215,0, 0.2)), url(${mountain})`, backgroundSize: 'cover', borderRadius: '.5rem' }} >
                <Typography variant='h1' sx={{ mt: '6rem', textAlign: 'center', fontSize: '3rem', fontFamily: '"DM Sans", Arial, sans-serif', fontWeight: 700 }}>Explore with us</Typography>
                <Typography variant='h1' sx={{ fontSize: '2.5rem', marginBottom: '2rem', textAlign: 'center', fontFamily: '"DM Sans", Arial, sans-serif', fontWeight: 700, color: '#d5c28f' }}>stories throughout Nepal</Typography>
                <>
                    <Grid container spacing={1}>
{/*                         <Grid item xs={12} md={7}> */}
{/*                             <Searchbar /> */}
{/*                         </Grid> */}
                        <Grid item xs={12} md={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={6} textAlign='right'>
                                    <Link to="/browse/stories"><Button variant="contained" size="large" sx={{ fontWeight: 600, color: '#ffffff', pt: 2, pb: 2, backgroundColor: 'rgb(3, 111, 17, 0.8)', '&:hover': { backgroundColor: '#213785' } }}>Browse stories</Button></Link>
                                </Grid>
                                <Grid item xs={6} textAlign='left'>
                                    <Link to="/learnnepali"><Button variant="contained" size="large" sx={{ fontWeight: 600, mr: 2, color: '#ffffff', pt: 2, pb: 2, backgroundColor: 'rgb(23, 38, 92, 0.8)', '&:hover': { backgroundColor: '#213785' } }}>Learn Nepali</Button></Link>
                                </Grid>
                            </Grid>

                        </Grid>

{/*                         <Grid item xs={12} md={4}> */}
{/*                             <Link to="/browse/places/map"><Button variant="contained" size="large" sx={{ fontWeight: 600, mr: 2, color: '#ffffff', pt: 2, pb: 2, backgroundColor: 'rgb(23, 38, 92, 0.8)', '&:hover': { backgroundColor: '#213785' } }}>Find in map</Button></Link> */}
{/*                             <Link to="/browse/places"><Button variant="contained" size="large" sx={{ fontWeight: 600, color: '#ffffff', pt: 2, pb: 2, backgroundColor: 'rgb(3, 111, 17, 0.8)', '&:hover': { backgroundColor: '#213785' } }}>Browse places</Button></Link> */}
{/*                         </Grid> */}
                    </Grid>
                </>
            </Grid>
        </Grid>
    )
}

export default HeaderSection;