import React, { useState, useEffect } from 'react';
import { getDistrictInfo } from './../api/getLocations';
import { useParams } from 'react-router-dom';
import PlacesCard from '../components/Cards/PlacesCard';
import { Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import LaunchIcon from '@mui/icons-material/Launch';
import {
    Card,
    Divider,
    ListItem
} from '@mui/material';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import AdBox from '../components/AdBox/AdBox';

const BrowsePlaces = () => {
    const [districtDetails, setDistrictDetails] = useState({});
    const { district } = useParams();
    const majorAttractions = ["majorAttraction 1", "majorAttraction 2"]

    useEffect(() => {
        getDistrictInfo(district)
            .then(response => response.json())
            .then(data => setDistrictDetails(data));
    }, [district])

    return (
        <Paper variant='outlined'>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    <Container fixed>
                        <Typography variant='h1' sx={{ fontWeight: '800', fontSize: '30px', color: '#004747' }} >
                            {districtDetails.name} [ {districtDetails.nameNepali} ]
                        </Typography>
                        <Typography variant='body1' sx={{ mb: 4 }}><strong>Headquarter</strong>: {districtDetails.headquarter} [ {districtDetails.headquarterNepali} ]</Typography>
                        <Divider />
                        {districtDetails.places && districtDetails.places.length > 0
                            ?
                            <>
                                <Typography variant='h4' sx={{ mb: 2, mt: 2, fontSize: '20px' }}>Select a place</Typography>
                                <Grid container spacing={4}>
                                    {districtDetails.places && districtDetails.places.map((place, index) =>
                                        <Grid item xs={'auto'} key={index}>
                                            <PlacesCard placeName={place.placeName} placeNameNepali={place.placeNameNepali} placeType={place.placeType} link={`/place/${place.id}`} />
                                        </Grid>
                                    )}
                                    <Divider />
                                </Grid>
                                <Divider />
                                <Typography variant='body1' sx={{ wordSpacing: '10px', letterSpacing: 1, mt: 2, fontSize: '16px' }}>Want to add places around you or places you love?</Typography>

                                <Typography variant='body1' sx={{ wordSpacing: '10px', letterSpacing: 1, mt: 2, fontSize: '16px' }}>Send us a message in our facebook page: <a href="">Attractions Nepal</a></Typography>
                            </>
                            :
                            <>
                                <Typography variant='h6' sx={{ wordSpacing: '10px', letterSpacing: 1, mt: 4, mb: 4 }}>No places available in {districtDetails.name}!</Typography>
                                <Typography variant='body1' sx={{ wordSpacing: '10px', letterSpacing: 1, mt: 2 }}>Want to add places around you or places you love?</Typography>
                                <Typography variant='body1' sx={{ wordSpacing: '10px', letterSpacing: 1, mt: 2 }}>Send us a message in our facebook page: <a href="https://www.facebook.com/profile.php?id=61558843006866">Attractions Nepal</a></Typography>
                            </>
                        }

                    </Container>
                    <Container>
                        {districtDetails.description && (
                            <Card fixed elevation={10}>
                                <Typography variant='body1' sx={{ fontWeight: '600', fontSize: '20px' }}>About {districtDetails.name}</Typography>
                                <Typography variant='body1'>{districtDetails.description}</Typography>
                            </Card>
                        )}
                    </Container>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    {districtDetails.website != null && districtDetails.website !== '' &&
                        <Card fixed elevation={10}>
                            <Typography variant='body1' sx={{ textDecoration: 'none' }}>Website: <Link href={districtDetails.website} sx={{ textDecoration: 'none' }}>{districtDetails.website}<LaunchIcon /></Link></Typography>
                        </Card>
                    }
                    <Divider />
                    {districtDetails.majorAttractions && districtDetails.majorAttractions.length > 0 && (
                        <Card elevation={10} sx={{ mt: 5, mb: 2 }}>
                            <Typography variant='body1' sx={{ mb: 2, mt: 2 }}>
                                <strong>Top attractions:</strong> {districtDetails.majorAttractions
                                    .map((attraction) =>
                                        <ListItem>{attraction}</ListItem>)}
                            </Typography>
                        </Card>
                    )}
                    <AdBox/>
                </Grid>
            </Grid>
        </Paper>
    )

}

export default BrowsePlaces
