import { useAuth } from '.././utils/auth';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { 
    Button, 
    Card, 
    TextField, 
    CardActionArea, 
    CardActions, 
    Typography, 
    CardContent,
    Grid,
 } from '@mui/material';
import { usePasswordValidation } from "../utils/usePasswordValidation";

export default function SignUp() {
    const auth = useAuth();
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [error, setError] = useState({
        passwordValidation: '',
        serverMessage: '',
    });

    const [password, setPassword] = useState({
        firstPassword: '',
        secondPassword: '',
    });

    const [
        validLength,
        hasNumber,
        upperCase,
        lowerCase,
        match,
        specialChar,
        ] = usePasswordValidation({
        firstPassword: password.firstPassword,
        secondPassword: password.secondPassword,
    });

    const setFirstPassword = (event) => {
        setPassword({ ...password, firstPassword: event.target.value });
    };

    const setSecondPassword = (event) => {
    setPassword({ ...password, secondPassword: event.target.value });
    };

    const signUp = () => {
        if (validLength &&
            hasNumber &&
            upperCase &&
            lowerCase &&
            match &&
            specialChar) {
                setError({ ...error, passwordValidation: '' });
                auth.signup(email, password.firstPassword)
                    .then(() => {
                        navigate('/signin');
                        window.alert('Successfully signed up. Please login to continue');                
                    })
                    .catch((err) => {
                        setError({ ...error, serverMessage: err.message});
                });
            } else {
                setError({ ...error, passwordValidation: 'Please turn all the red crosses to green ticks first'});
            }
    };

    return (
        <Card raised> 
            <CardActionArea>
                <Typography gutterBottom variant="h5" component="h3">
                    Sign Up
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={8} lg={6}>
                        <CardContent>
                            <TextField
                                error={error.serverMessage}
                                id="email-signup-textfield"
                                label="Enter your email"
                                helperText={error.serverMessage}
                                variant="outlined"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                type={"email"}
                                sx={{mb: 4}}
                            />
                            <br/>
                            <TextField
                                error={error.passwordValidation}
                                id="first-password-signup-textfield"
                                label="Enter a password"
                                helperText={error.passwordValidation}
                                variant="outlined"
                                value={password.firstPassword} 
                                onChange={setFirstPassword} 
                                required
                                type={"password"}
                                sx={{ mb: 4 }}
                            />
                            <TextField
                                id="second-password-reset-textfield"
                                label="Confirm password"
                                helperText="Passwords must contain at least eight characters, including uppercase, lowercase letters and numbers."
                                variant="outlined"
                                value={password.secondPassword} 
                                onChange={setSecondPassword} 
                                required
                                type={"password"}
                            />
                        </CardContent>
                    </Grid>
                    <Grid xs={12} sm={12} md={4} lg={6}>
                        <div>
                            <ul>
                                <li>
                                    Valid Length: {validLength ? <span style={{ color: "green" }}>✔</span> : <span style={{ color: "red"}}>✘</span>}
                                </li>
                                <li>
                                    Has a Number: {hasNumber ? <span style={{color: "green"}}>✔</span> : <span style={{color: "red"}}>✘</span>}
                                </li>
                                <li>
                                    UpperCase: {upperCase ? <span style={{color: "green"}}>✔</span> : <span style={{color: "red"}}>✘</span>}
                                </li>
                                <li>
                                    LowerCase: {lowerCase ? <span style={{color: "green"}}>✔</span> : <span style={{color: "red"}}>✘</span>}
                                </li>
                                <li>Passwords Match: {match ? <span style={{color: "green"}}>✔</span> : <span style={{color: "red"}}>✘</span>}</li>
                                <li>
                                    Special Character:{" "}
                                    {specialChar ? <span style={{color: "green"}}>✔</span> : <span style={{color: "red"}}>✘</span>}
                                </li>
                            </ul>
                        </div> 
                    </Grid>
                </Grid>
            </CardActionArea>
            <CardActions>
                <Button variant="contained" size="medium" onClick={()=>signUp()}>
                    Sign Up
                </Button>
                <Link to="/signin">Already registered? Sign In</Link>
            </CardActions>
        </Card>
    );
}