import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import SocialButton from '../SocialButton/SocialButton';

const Footer = () => {
    const yearToday = new Date().getFullYear();
    return (
            <footer>
                <div className="footer-text-container">
                    <span className="footer-name">ⒸAttractions Nepal {yearToday}</span>
            </div>
            <div className="footer-link-container">
{/*                 <Link to="browse/places" className="footer-link">Browse places</Link> */}
{/*                 <Link to="browse/places/map" className="footer-link">Find in map</Link> */}
                <Link to="browse/stories" className="footer-link">Stories</Link>
                <Link to="/about" className="footer-link">About</Link>
            </div>
            <br />
            <div className='social-buttons-container'>
                <SocialButton
                    platform="f"
                    size="small"
                    backgroundColor="#1877f2"
                    link="https://www.facebook.com/profile.php?id=61558843006866"
                />

                <SocialButton
                    platform="in"
                    size="small"
                    backgroundColor="#1da1f2"
                    link="https://www.linkedin.com/company/attractionsnepal"
                />
            </div>


            </footer>
    )
}

export default Footer